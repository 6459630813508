import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { usePostRequestSyncPromise } from "../../global/GlobalFetch";
import ReactPaginate from 'react-paginate';
import Slider from 'react-slick';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Select from 'react-select';
import { FaTimes } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faVideoSlash, faSearch, faHistory, faFilm, faCheckCircle, faTimesCircle, faExclamationTriangle, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Movie.css';
import * as signalR from '@microsoft/signalr';
import { ToastContainer, toast } from 'react-toastify';
import { CONFIG } from '../../CONFIG';
const showSuccessToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faCheckCircle} />{message}</div>);
};
const showErrorToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faTimesCircle} />{message}</div>);
};
const showWarningToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faExclamationTriangle} />{message}</div>);
};
const showInfoToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faInfoCircle} />{message}</div>);
};
const UserHistoryPopup = ({
    isOpen,
    toggle,
    allUsers,
    allUsersHistory,
    setPopupVisible,
    setSelectedMovie,
    postRequestSyncPromise,
    setAllUsersHistory,
}) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [userPages, setUserPages] = useState({});
    const [localHistories, setLocalHistories] = useState([]);
    const HISTORIES_PER_PAGE = 8;

    useEffect(() => {
        setLocalHistories(allUsersHistory);
    }, [allUsersHistory]);

    const handleUserChange = (selectedOptions) => {
        setSelectedUsers(selectedOptions ? selectedOptions.map((option) => option.value) : []);
        setUserPages({});
    };

    const handlePageChange = (user, newPage) => {
        setUserPages((prevPages) => ({
            ...prevPages,
            [user]: newPage,
        }));
    };

    const userHistories = selectedUsers.map((user) => {
        const histories = localHistories
            .filter((history) => history.owner === user && history.historyType === 1)
            .sort((a, b) => {
                const dateA = new Date(a.updatedDate || a.createdDate);
                const dateB = new Date(b.updatedDate || b.createdDate);
                return dateB - dateA;
            });

        const totalPages = Math.ceil(histories.length / HISTORIES_PER_PAGE);
        const currentPage = userPages[user] || 1;

        const currentHistory = histories.slice(
            (currentPage - 1) * HISTORIES_PER_PAGE,
            currentPage * HISTORIES_PER_PAGE
        );

        return {
            user,
            history: currentHistory.map((history) => history.movieDetail),
            totalPages,
            currentPage,
        };
    });

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderColor: state.isFocused ? 'var(--glow-color)' : 'var(--border-color)',
            boxShadow: state.isFocused ? `0 0 8px var(--glow-color)` : 'none',
            '&:hover': {
                borderColor: 'var(--glow-color)',
            },
            borderRadius: '8px',
            padding: '5px',
            color: 'var(--text-color)',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: 'var(--hover-gradient-2)',
            borderRadius: '4px',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'var(--text-color)',
            fontWeight: 'bold',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'var(--text-color)',
            '&:hover': {
                backgroundColor: 'var(--hover-gradient-1)',
                color: 'var(--text-color)',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'rgba(255, 255, 255, 0.6)',
            fontStyle: 'italic',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isFocused ? 'var(--glow-color)' : 'var(--text-color)',
            '&:hover': {
                color: 'var(--glow-color)',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'rgba(32, 43, 96, 0.9)',
            border: '1px solid var(--border-color)',
            boxShadow: `0 8px 16px var(--glow-color)`,
            borderRadius: '8px',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'var(--hover-gradient-2)'
                : state.isFocused
                    ? 'var(--hover-gradient-1)'
                    : 'transparent',
            color: 'var(--text-color)',
            '&:active': {
                backgroundColor: 'var(--primary-gradient-2)',
            },
        }),
    };

    const handleCardClick = (movie) => {
        setSelectedMovie(movie);
        setPopupVisible(true);
    };

    const handleDeleteHistory = (movieDetail, user) => {
        const userCode = localStorage.getItem('userCode');

        const baseRequest = {
            data: [{
                userName: userCode,
                id: movieDetail.id,
                historyType: 1
            }]
        };

        postRequestSyncPromise('UserHistory/DeleteUserHistory', baseRequest)
            .then(data => {
                if (data.type === 0) {
                }
            })
            .catch(error => {
                console.error('Error deleting history:', error);
            });
    };

    return (
        <CSSTransition in={isOpen} timeout={300} classNames="modal-animation" unmountOnExit>
            <Modal isOpen={isOpen} toggle={toggle} size="xl" centered className="custom-modal">
                <ModalBody className="modal-body-custom">
                    <div className="sticky-select">
                        <Select
                            options={allUsers.map((user) => ({ value: user, label: user }))}
                            onChange={handleUserChange}
                            placeholder="Select users..."
                            isClearable
                            isMulti
                            styles={customStyles}
                            classNamePrefix="select"
                        />
                    </div>
                    <TransitionGroup component={Row} className="history-content">
                        {userHistories.map(({ user, history, totalPages, currentPage }) => (
                            <CSSTransition key={user} timeout={300} classNames="fade">
                                <Col xs="12">
                                    <h5 className="history-header">{user}'s History</h5>
                                    {history.length > 0 ? (
                                        <>
                                            <TransitionGroup component={Row}>
                                                {history.map((movieDetail, idx) => (
                                                    <CSSTransition key={`${user}-${idx}`} timeout={300} classNames="fade">
                                                        <Col xs="12" sm="6" md="4" lg="3" className="mb-3">
                                                            <div className="movie-card-wrapper">
                                                                {user === localStorage.getItem('userCode') ? (
                                                                    <MovieCard
                                                                        movie={movieDetail}
                                                                        onClick={() => handleCardClick(movieDetail)}
                                                                        onDelete={() => handleDeleteHistory(movieDetail, user)}
                                                                    />
                                                                ) : (
                                                                    <MovieCard
                                                                        movie={movieDetail}
                                                                        onClick={() => handleCardClick(movieDetail)}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </CSSTransition>
                                                ))}
                                            </TransitionGroup>
                                            {totalPages > 1 && (
                                                <Pagination className="justify-content-center custom-pagination">
                                                    <PaginationItem
                                                        className="animated-pagination-item"
                                                        disabled={currentPage === 1}
                                                    >
                                                        <PaginationLink
                                                            className="pagination-button"
                                                            previous
                                                            onClick={() => handlePageChange(user, currentPage - 1)}
                                                        />
                                                    </PaginationItem>
                                                    {[...Array(totalPages)].map((_, i) => (
                                                        <PaginationItem
                                                            active={i + 1 === currentPage}
                                                            key={`${user}-page-${i}`}
                                                            className={
                                                                i + 1 === currentPage
                                                                    ? 'active-page'
                                                                    : 'animated-pagination-item'
                                                            }
                                                        >
                                                            <PaginationLink
                                                                className="pagination-button"
                                                                onClick={() => handlePageChange(user, i + 1)}
                                                            >
                                                                {i + 1}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    ))}
                                                    <PaginationItem
                                                        className="animated-pagination-item"
                                                        disabled={currentPage === totalPages}
                                                    >
                                                        <PaginationLink
                                                            className="pagination-button"
                                                            next
                                                            onClick={() => handlePageChange(user, currentPage + 1)}
                                                        />
                                                    </PaginationItem>
                                                </Pagination>
                                            )}
                                        </>
                                    ) : (
                                        <p className="no-history-message">No history available for {user}.</p>
                                    )}
                                </Col>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                </ModalBody>
            </Modal>
        </CSSTransition>
    );
};
const FilterTypeSelect = ({ filterType, setFilterType }) => {
    const options = [
        { value: 'AND', label: 'AND' },
        { value: 'OR', label: 'OR' }
    ];

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderColor: state.isFocused ? 'var(--glow-color)' : 'var(--border-color)',
            boxShadow: state.isFocused ? `0 0 8px var(--glow-color)` : 'none',
            '&:hover': {
                borderColor: 'var(--glow-color)',
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--text-color)',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'rgba(255, 255, 255, 0.6)',
            fontStyle: 'italic',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isFocused ? 'var(--glow-color)' : 'var(--text-color)',
            '&:hover': {
                color: 'var(--glow-color)',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'rgba(32, 43, 96, 0.9)',
            border: '1px solid var(--border-color)',
            boxShadow: `0 8px 16px var(--glow-color)`,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'var(--hover-gradient-2)'
                : state.isFocused
                    ? 'var(--hover-gradient-1)'
                    : 'transparent',
            color: 'var(--text-color)',
            '&:active': {
                backgroundColor: 'var(--primary-gradient-2)',
            },
        }),
    };

    return (
        <Select
            options={options}
            value={options.find(option => option.value === filterType)}
            onChange={(selectedOption) => setFilterType(selectedOption.value)}
            placeholder="Select Filter..."
            styles={customStyles}
            className="filter-type-select"
            classNamePrefix="select"
            isSearchable={false}
        />
    );
};
const GenreSelector = ({ allMovieGenres, selectedGenres, setSelectedGenres }) => {
    const genreOptions = allMovieGenres.map(genre => ({
        value: genre.id,
        label: genre.name
    }));

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderColor: state.isFocused ? 'var(--glow-color)' : 'var(--border-color)',
            boxShadow: state.isFocused ? `0 0 8px var(--glow-color)` : 'none',
            '&:hover': {
                borderColor: 'var(--glow-color)',
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: 'var(--hover-gradient-2)',
            borderRadius: '4px',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'var(--text-color)',
            fontWeight: 'bold',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'var(--text-color)',
            '&:hover': {
                backgroundColor: 'var(--hover-gradient-1)',
                color: 'var(--text-color)',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'rgba(255, 255, 255, 0.6)',
            fontStyle: 'italic',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isFocused ? 'var(--glow-color)' : 'var(--text-color)',
            '&:hover': {
                color: 'var(--glow-color)',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'rgba(32, 43, 96, 0.9)',
            border: '1px solid var(--border-color)',
            boxShadow: `0 8px 16px var(--glow-color)`,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'var(--hover-gradient-2)'
                : state.isFocused
                    ? 'var(--hover-gradient-1)'
                    : 'transparent',
            color: 'var(--text-color)',
            '&:active': {
                backgroundColor: 'var(--primary-gradient-2)',
            },
        }),
    };

    const handleChange = (selectedOptions) => {
        setSelectedGenres(selectedOptions || []);
    };

    return (
        <Select
            options={genreOptions}
            isMulti
            value={selectedGenres}
            onChange={handleChange}
            placeholder="Select Genres..."
            className="genre-select"
            styles={customStyles}
            classNamePrefix="select"
            closeMenuOnSelect={false}
        />
    );
};
const MovieWatch = ({ isOpen, toggle, movieId }) => {
    const videoUrl = `https://vidsrc.to/embed/movie/${movieId}`;
    const videoUrlWithSub = `https://vidsrc.xyz/embed/movie?tmdb=${movieId}&ds_langs=en,tr`;

    const iframeRef1 = useRef(null);
    const iframeRef2 = useRef(null);

    useEffect(() => {
        const handleIframeEvents = (iframe) => {
            try {
                const iframeWindow = iframe.current?.contentWindow;
                if (iframeWindow) {
                    iframeWindow.addEventListener('beforeunload', (event) => {
                        event.preventDefault();
                    });
                    window.addEventListener('message', (event) => {
                        if (event.origin === 'https://vidsrc.to' || event.origin === 'https://vidsrc.xyz') {
                        }
                    });
                }
            } catch (error) {
                console.error('Error accessing iframe content:', error);
            }
        };
        handleIframeEvents(iframeRef1);
        handleIframeEvents(iframeRef2);
        return () => {
            window.removeEventListener('message', handleIframeEvents);
        };
    }, [isOpen]);

    return (
        <CSSTransition in={isOpen} timeout={300} classNames="modal-animation" unmountOnExit>
            <Modal isOpen={isOpen} toggle={toggle} size="xl" className="movie-watch-modal custom-modal" centered>
                <ModalHeader toggle={toggle} className="modal-header-custom">
                    Watch Movie
                </ModalHeader>
                <ModalBody className="modal-body-custom">
                    <div className="video-container">
                        <div className="iframe-wrapper">
                            <div className="video-wrapper">
                                <iframe
                                    ref={iframeRef1}
                                    src={`${videoUrl}?autoplay=1`}
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    title="Movie Watch"
                                    className="video-iframe"
                                    referrerPolicy="origin"
                                />
                            </div>
                            <div className="video-wrapper">
                                <iframe
                                    ref={iframeRef2}
                                    src={`${videoUrlWithSub}&autoplay=1`}
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    title="Movie Watch With Subtitles"
                                    className="video-iframe"
                                    referrerPolicy="origin"
                                />
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </CSSTransition>
    );
};
MovieWatch.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    movieId: PropTypes.number.isRequired,
};
const MovieCard = ({ movie, onClick, onDelete }) => {
    const imageUrl = `https://image.tmdb.org/t/p/w300_and_h450_bestv2${movie.poster_path}`;

    return (
        <div className="movie-card" onClick={() => onClick(movie)}>
            <div className="card-image">
                <img src={imageUrl} alt={movie.title} loading='eager' />
            </div>
            <div className="card-content">
                <h5 className="card-title">{movie.title}</h5>
                {onDelete && (
                    <button
                        className="close-icon"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onDelete();
                        }}
                    >
                        <FaTimes />
                    </button>
                )}
            </div>
        </div>
    );
};
MovieCard.propTypes = {
    movie: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
};
const MovieDetailTab = ({ data: movieId, onClose, allUsersHistory, setAllUsersHistory }) => {
    const postRequestSyncPromise = usePostRequestSyncPromise();
    const [details, setDetails] = useState(null);
    const [isWatchPopupOpen, setWatchPopupOpen] = useState(false);
    const [similars, setSimilars] = useState([]);
    const [watchUrl, setWatchUrl] = useState('');
    const [currentMovieId, setCurrentMovieId] = useState(movieId);
    const [isFragmanPopupOpen, setFragmanPopupOpen] = useState(false);
    const [fragmanUrls, setFragmanUrls] = useState([]);

    const fetchSimilars = (movieId) => {
        const baseReq = {
            data: [`${movieId}`],
        };
        postRequestSyncPromise('Movie/GetSimilarMovies', baseReq)
            .then(data => {
                setSimilars(data.data[0].results);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const fetchMovieDetails = (movieId) => {
        const detailFilter = {
            id: `${movieId}`,
            language: 'tr-TR',
            append_to_response: 'videos'
        };

        const baseRequest = {
            requestId: 'unique_request_id',
            sender: 'client_tab_movie_details',
            data: [detailFilter]
        };

        postRequestSyncPromise('Movie/MovieDetail', baseRequest)
            .then(data => {
                setDetails(data.data[0]);
                setCurrentMovieId(movieId);
            })
            .catch(error => console.error('Fetch error:', error));
    };

    const postUserHistory = (details) => {
        const userCode = localStorage.getItem('userCode');
        const existingHistory = allUsersHistory.find(
            history => history.owner === userCode && history?.movieDetail?.id === details.id
        );

        let historyData = {
            owner: userCode,
            historyType: 1,
            movieDetail: details
        };

        if (existingHistory) {
            historyData._id = existingHistory._id;
            historyData.createdDate = existingHistory.createdDate;
        }

        const baseRequest = { data: [historyData] };
        const endpoint = existingHistory ? 'UserHistory/UpdateUserHistory' : 'UserHistory/CreateUserHistory';

        postRequestSyncPromise(endpoint, baseRequest)
            .then(data => {
            })
            .catch(error => {
                console.error("Error in posting user history:", error);
            });
    };

    useEffect(() => {
        if (movieId) {
            fetchMovieDetails(movieId);
            fetchSimilars(movieId);
        }
    }, [movieId]);

    const handleSimilarMovieClick = (similarMovieId) => {
        fetchMovieDetails(similarMovieId);
        fetchSimilars(similarMovieId);
    };

    const renderItems = (items) => {
        return items && items.length > 0
            ? items.map((item, index) => (
                <span key={index}>{item.name || item.english_name}</span>
            )).reduce((prev, curr) => [prev, ', ', curr])
            : 'N/A';
    };

    const toggleWatchPopup = (type) => {
        const baseVideoUrl = `https://vidsrc.to/embed/movie/${currentMovieId}`;
        const videoUrlWithSub = `https://vidsrc.xyz/embed/movie?tmdb=${currentMovieId}&ds_langs=en,tr`;
        setWatchUrl(type === 'withSub' ? videoUrlWithSub : baseVideoUrl);
        setWatchPopupOpen(true);
        postUserHistory(details);
    };

    const openFragmanPopup = (videos) => {
        const urls = videos.map((video) => `https://www.youtube.com/embed/${video.key}`);
        setFragmanUrls(urls);
        setFragmanPopupOpen(true);
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        pauseOnHover: true
    };

    return (
        <CSSTransition in={true} timeout={300} classNames="modal-animation" unmountOnExit>
            <Modal isOpen={true} toggle={onClose} size="xl" centered className="movie-detail-modal">
                <ModalBody className="modal-body-custom">
                    {details ? (
                        <div>
                            <button className="modal-close-button" onClick={onClose}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <div className="movie-card-detail">
                                <img
                                    src={`https://image.tmdb.org/t/p/w500${details.poster_path}`}
                                    alt={details.title}
                                    className="detail-poster"
                                    loading='eager'
                                />
                                <div className="detail-info">
                                    <h2 className="detail-title">{details.title}</h2>
                                    <p className="detail-overview">{details.overview || 'N/A'}</p>

                                    <div className="movie-details-grid">
                                        <div className="detail-section">
                                            <span className="detail-label">Genres:</span>
                                            <span className="detail-value">{renderItems(details.genres)}</span>
                                        </div>
                                        <div className="detail-section">
                                            <span className="detail-label">Release Date:</span>
                                            <span className="detail-value">{details.release_date || 'N/A'}</span>
                                        </div>
                                        <div className="detail-section">
                                            <span className="detail-label">Runtime:</span>
                                            <span className="detail-value">{details.runtime || 'N/A'} minutes</span>
                                        </div>
                                        <div className="detail-section">
                                            <span className="detail-label">Vote Average:</span>
                                            <span className="detail-value">{details.vote_average || 'N/A'}</span>
                                        </div>
                                        <div className="detail-section">
                                            <span className="detail-label">Popularity:</span>
                                            <span className="detail-value">{details.popularity || 'N/A'}</span>
                                        </div>
                                        <div className="detail-section">
                                            <span className="detail-label">Status:</span>
                                            <span className="detail-value">{details.status || 'N/A'}</span>
                                        </div>
                                    </div>

                                    <div className="detail-section videos-section">
                                        <span className="detail-label">Videos:</span>
                                        <span className="detail-value">
                                            {details.videos.results.length > 0 ? (
                                                <button
                                                    className="video-link-button"
                                                    onClick={() => openFragmanPopup(details.videos.results)}
                                                >
                                                    Watch Trailers
                                                </button>
                                            ) : (
                                                'N/A'
                                            )}
                                        </span>
                                    </div>

                                    <div className="movie-detail-actions">
                                        <button className="stylish-button" onClick={() => toggleWatchPopup('noSub')}>
                                            Watch Movie
                                        </button>
                                        <button className="stylish-button" onClick={() => toggleWatchPopup('withSub')}>
                                            Watch Movie With Subtitles
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <h2 className="similar-movies-title">Similar Movies</h2>
                            <Slider {...sliderSettings} className="similar-movies-slider">
                                {similars.map((movie) => (
                                    <div
                                        key={movie.id}
                                        className="similar-movie-card"
                                        onClick={() => handleSimilarMovieClick(movie.id)}
                                    >
                                        <img
                                            src={`https://image.tmdb.org/t/p/w200${movie.poster_path}`}
                                            alt={movie.title}
                                            className="similar-movie-poster"
                                            loading='eager'
                                        />
                                        <div className="similar-movie-info">
                                            <h6 className="similar-movie-title">{movie.title}</h6>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    ) : (
                        <div className="loading">Loading...</div>
                    )}
                </ModalBody>

                {isWatchPopupOpen && (
                    <Modal
                        isOpen={isWatchPopupOpen}
                        toggle={() => setWatchPopupOpen(false)}
                        size="xl"
                        centered
                        className="custom-modal"
                    >
                        <ModalBody className="modal-body-watch">
                            <div className="video-wrapper">
                                <iframe
                                    src={`${watchUrl}?autoplay=1`}
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    title="Movie Watch"
                                    className="video-iframe"
                                />
                            </div>
                        </ModalBody>
                    </Modal>
                )}
                <MovieFragmanWatch
                    isOpen={isFragmanPopupOpen}
                    toggle={() => setFragmanPopupOpen(false)}
                    fragmanUrls={fragmanUrls}
                />
            </Modal>
        </CSSTransition>
    );
};
MovieDetailTab.propTypes = {
    data: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired
};
const MovieFragmanWatch = ({ isOpen, toggle, fragmanUrls }) => {
    const [activeFragman, setActiveFragman] = useState(0);
    const iframeRefs = useRef([]);

    const handlePrevFragman = () => {
        setActiveFragman((prev) => (prev > 0 ? prev - 1 : fragmanUrls.length - 1));
    };

    const handleNextFragman = () => {
        setActiveFragman((prev) => (prev < fragmanUrls.length - 1 ? prev + 1 : 0));
    };

    useEffect(() => {
        if (!isOpen) {
            setActiveFragman(0);
        }
    }, [isOpen]);

    return (
        <CSSTransition in={isOpen} timeout={300} classNames="modal-animation" unmountOnExit>
            <Modal isOpen={isOpen} toggle={toggle} size="xl" className="fragman-watch-modal" centered>
                <div className="fragman-header">
                    <span className="fragman-title">
                        Fragman {activeFragman + 1}/{fragmanUrls.length}
                    </span>
                    <button className="close-button" onClick={toggle}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className="fragman-body">
                    {fragmanUrls.length > 0 ? (
                        <div className="fragman-player">
                            {fragmanUrls.length > 1 && (
                                <button
                                    className="nav-button prev"
                                    onClick={handlePrevFragman}
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                            )}
                            <div className="fragman-wrapper">
                                <iframe
                                    key={activeFragman}
                                    src={`${fragmanUrls[activeFragman]}?autoplay=1&rel=0`}
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    title={`Fragman ${activeFragman + 1}`}
                                    className="fragman-iframe"
                                />
                            </div>
                            {fragmanUrls.length > 1 && (
                                <button
                                    className="nav-button next"
                                    onClick={handleNextFragman}
                                >
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            )}
                        </div>
                    ) : (
                        <div className="no-fragman">
                            <FontAwesomeIcon icon={faVideoSlash} size="3x" />
                            <p>Fragman bulunamadı</p>
                        </div>
                    )}
                </div>
            </Modal>
        </CSSTransition>
    );
};
MovieFragmanWatch.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    fragmanUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
};
MovieFragmanWatch.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    fragmanUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
};
const SliderContent = ({ movieId, postRequestSyncPromise, allUsersHistory }) => {
    const [movieDetails, setMovieDetails] = useState(null);
    const [isWatchPopupOpen, setWatchPopupOpen] = useState(false);
    const [watchUrl, setWatchUrl] = useState('');
    const [isFragmanPopupOpen, setFragmanPopupOpen] = useState(false);
    const [fragmanUrls, setFragmanUrls] = useState([]);

    const postUserHistory = (details) => {
        const userCode = localStorage.getItem('userCode');
        const existingHistory = allUsersHistory.find(
            history => history.owner === userCode && history?.movieDetail?.id === details.id
        );

        let historyData = {
            owner: userCode,
            historyType: 1,
            movieDetail: details
        };

        if (existingHistory) {
            historyData._id = existingHistory._id;
            historyData.createdDate = existingHistory.createdDate;
        }

        const baseRequest = { data: [historyData] };
        const endpoint = existingHistory ? 'UserHistory/UpdateUserHistory' : 'UserHistory/CreateUserHistory';

        fetch(CONFIG.BaseUrl + endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(baseRequest)
        })
            .then(response => response.json())
            .then(data => {
                if (data.type === 0) {
                    console.log("User history posted successfully.");
                } else {
                    console.log("Error in posting user history:", data);
                }
            })
            .catch(error => {
                console.error("Error in posting user history:", error);
            });
    };

    useEffect(() => {
        const fetchMovieDetails = () => {
            const detailFilter = {
                id: `${movieId}`,
                language: 'tr-TR',
                append_to_response: 'videos'
            };
            const baseRequest = {
                requestId: 'unique_request_id',
                sender: 'client_tab_movie_details',
                data: [detailFilter]
            };
            postRequestSyncPromise('Movie/MovieDetail', baseRequest)
                .then(data => {
                    setMovieDetails(data.data[0]);
                })
                .catch(error => console.error('Fetch error:', error));
        };

        fetchMovieDetails();
    }, [movieId]);

    const toggleWatchPopup = () => {
        const videoUrlWithSub = `https://vidsrc.xyz/embed/movie?tmdb=${movieId}&ds_langs=en,tr`;
        setWatchUrl(videoUrlWithSub);
        setWatchPopupOpen(true);
        if (movieDetails) {
            postUserHistory(movieDetails);
        } else {
            console.error('movieDetails is not available.');
        }
    };

    const openFragmanPopup = (videos) => {
        const urls = videos.map((video) => `https://www.youtube.com/embed/${video.key}`);
        setFragmanUrls(urls);
        setFragmanPopupOpen(true);
    };

    if (!movieDetails) {
        return <div>Loading...</div>;
    }

    const renderItems = (items) => {
        return items && items.length > 0
            ? items.map((item) => item.name || item.english_name).join(', ')
            : 'N/A';
    };

    return (
        <div className="slider-content-wrapper">
            <div className="slider-content">
                <div
                    className="slider-content__backdrop"
                    style={{
                        backgroundImage: `url(https://image.tmdb.org/t/p/original${movieDetails.backdrop_path})`
                    }}
                >
                    <div className="slider-content__overlay">
                        <div className="slider-content__info">
                            <h2 className="slider-content__title">{movieDetails.title}</h2>
                            <div className="slider-content__meta">
                                <span className="release-date">{movieDetails.release_date}</span>
                                <span className="rating">
                                    <i className="fas fa-star"></i> {movieDetails.vote_average}
                                </span>
                                <span className="genres">{renderItems(movieDetails.genres)}</span>
                            </div>
                            <p className="slider-content__overview">{movieDetails.overview}</p>
                            <div className="slider-content__stats">
                                <div className="stat">
                                    <span className="label">Runtime</span>
                                    <span className="value">{movieDetails.runtime} min</span>
                                </div>
                                <div className="stat">
                                    <span className="label">Language</span>
                                    <span className="value">{movieDetails.original_language?.toUpperCase()}</span>
                                </div>
                                <div className="stat">
                                    <span className="label">Status</span>
                                    <span className="value">{movieDetails.status}</span>
                                </div>
                            </div>
                            <div className="slider-content__actions">
                                <button className="watch-button" onClick={toggleWatchPopup}>
                                    <i className="fas fa-play"></i> Watch Now
                                </button>
                                <button
                                    className="details-button"
                                    onClick={() => openFragmanPopup(movieDetails.videos?.results || [])}
                                >
                                    <i className="fas fa-film"></i> Watch Trailers
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {isWatchPopupOpen && (
                    <Modal
                        isOpen={isWatchPopupOpen}
                        toggle={() => setWatchPopupOpen(false)}
                        size="xl"
                        centered
                        className="custom-modal"
                    >
                        <ModalBody className="modal-body-watch">
                            <div className="video-wrapper">
                                <iframe
                                    src={`${watchUrl}?autoplay=1`}
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    title="Movie Watch"
                                    className="video-iframe"
                                />
                            </div>
                        </ModalBody>
                    </Modal>
                )}
                <MovieFragmanWatch
                    isOpen={isFragmanPopupOpen}
                    toggle={() => setFragmanPopupOpen(false)}
                    fragmanUrls={fragmanUrls}
                />
            </div>
        </div>
    );
};
export default function Movie() {
    //! SIGNALR CONNECTION
    const signalConnection = useRef(null);

    //! IS MOBILE
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    //! PAGE STATE
    const [pageCountInfo, setPageCountInfo] = useState(1);
    const [isSearchActive, setIsSearchActive] = useState(false);

    //! GLOBAL FETCH
    const postRequestSyncPromise = usePostRequestSyncPromise();

    //! MOVIE SEARCH
    const [movieSearchTextboxValue, setMovieSearchTextboxValue] = useState("");

    //! MOVIE DATA
    const [searchedMovieDataSource, setSearchedMovieDataSource] = useState([]);
    const [topMovies, setTopMovies] = useState([]);
    const [selectedMovie, setSelectedMovie] = useState(null);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [allMovieGenres, setAllMovieGenres] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [filterType, setFilterType] = useState("AND");
    const [allUsers, setAllUsers] = useState([]);
    const [allUsersHistory, setAllUsersHistory] = useState([]);
    const [isHistoryPopupVisible, setIsHistoryPopupVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        DiscoverMovie(1);
        FetchTopMovies();
        fetchMovieGenres();
    }, []);

    //! IS MOBILE
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        initializeSignalR();
    }, []);
    //! PAGE FUNCTIONS
    const handlePageClick = (data) => {
        let selectedPage = data.selected + 1;
        setCurrentPage(selectedPage);
        setSearchedMovieDataSource([]);
        if (isSearchActive) {
            SearchMovie(selectedPage);
        } else {
            DiscoverMovie(selectedPage);
        }
    };
    //! DISCOVER MOVIE
    const DiscoverMovie = useCallback((page = 1) => {
        if (page === 1) {
            setCurrentPage(1);
        }
        let pageInformation = page || 1;

        let withGenres = "";
        let genreIds = selectedGenres.map(genre => genre.value);
        if (filterType === "AND")
            withGenres = genreIds.join(',');
        else
            withGenres = genreIds.join('|');

        const discoverFilter = {
            include_adult: true,
            language: "tr-TR",
            page: pageInformation,
            with_genres: withGenres
        };

        const baseRequest = {
            RequestId: "unique_request_id",
            Sender: "client_discover_movie",
            Data: [discoverFilter]
        };

        postRequestSyncPromise("Movie/DiscoverMovie", baseRequest)
            .then(data => {
                setSearchedMovieDataSource(data.data[0].results);
                setPageCountInfo(data.data[0].total_pages || 500);
                window.scrollTo(0, 0);
            })
            .catch(error => {
                console.error(error);
            });
    }, [postRequestSyncPromise, selectedGenres, filterType]);

    //! SEARCH MOVIE
    const SearchMovie = useCallback((page = 1) => {
        if (page === 1) {
            setCurrentPage(1);
        }

        const searchFilter = {
            query: movieSearchTextboxValue,
            language: "tr-TR",
            page: page
        };

        const baseRequest = {
            RequestId: "unique_request_id",
            Sender: "client_search_movie",
            Data: [searchFilter]
        };

        postRequestSyncPromise("Movie/SearchMovie", baseRequest)
            .then(data => {
                setSearchedMovieDataSource(data.data[0].results);
                setPageCountInfo(data.data[0].total_pages);
                window.scrollTo(0, 0);
            })
            .catch(error => {
                console.error(error);
            });
    }, [movieSearchTextboxValue, postRequestSyncPromise]);

    //! FETCH TOP MOVIES
    const FetchTopMovies = useCallback(() => {
        const discoverFilter = {
            include_adult: false,
            language: "tr-TR",
            page: 1
        };

        const baseRequest = {
            RequestId: "unique_request_id",
            Sender: "client_discover_movie",
            Data: [discoverFilter]
        };

        postRequestSyncPromise("Movie/DiscoverMovie", baseRequest)
            .then(data => {
                const sortedMovies = data.data[0].results.sort((a, b) => b.popularity - a.popularity);
                const topTen = sortedMovies.slice(0, 10);
                setTopMovies(topTen);
            })
            .catch(error => {
                console.error(error);
            });
    }, [postRequestSyncPromise]);

    const fetchMovieGenres = useCallback(() => {
        postRequestSyncPromise('Movie/GetAllMovieGenres', {})
            .then(data => {
                setAllMovieGenres(data.data[0].genres);
            })
            .catch(error => {
                console.error(error);
            });
    }, [postRequestSyncPromise]);

    const fetchAllUserNames = useCallback(() => {
        return postRequestSyncPromise('Auth/GetAllUserNames', {})
            .then(data => {
                setAllUsers(data.data);
            })
            .catch(error => {
                console.error("Error fetching all user names:", error);
            });
    }, []);

    //! INITIALIZE SIGNALR
    const initializeSignalR = async () => {
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl("https://api.kaminarivi.com.tr/movieHub")
            .withAutomaticReconnect([1000, 2000, 3000, 5000, 10000])
            .build();

        async function start(connection) {
            try {
                await connection.start();
                localStorage.setItem("connectionId", connection.connectionId);
            } catch (error) {
                console.error(error);
                setTimeout(() => start(connection), 5000);
            }
        }
        start(newConnection);
        signalConnection.current = newConnection;
        newConnection.onreconnected(() => {
            showSuccessToast("Connection Reestablished.");
        });
        newConnection.onreconnected(() => {
            showSuccessToast("Connection Reestablished.");
        });
        newConnection.onclose(async (error) => {
            if (error) {
                console.error("SignalR connection closed with error: ", error);
            } else {
            }
        });
        newConnection.on("InsertUserHistory", (updatedUserHistory) => {
            setAllUsersHistory((prevHistories) => {
                if (updatedUserHistory.historyType !== 1) {
                    return prevHistories;
                }
                const existingIndex = prevHistories.findIndex(h => h._id === updatedUserHistory._id);
                if (existingIndex !== -1) {
                    const newHistories = [...prevHistories];
                    newHistories[existingIndex] = updatedUserHistory;
                    return newHistories;
                } else {
                    return [...prevHistories, updatedUserHistory];
                }
            });
        });
        newConnection.on("UpdateUserHistory", (updatedUserHistory) => {
            setAllUsersHistory((prevHistories) => {
                if (updatedUserHistory.historyType !== 1) {
                    return prevHistories;
                }

                const existingIndex = prevHistories.findIndex(h => h._id === updatedUserHistory._id);
                if (existingIndex !== -1) {
                    const newHistories = [...prevHistories];
                    newHistories[existingIndex] = updatedUserHistory;
                    return newHistories;
                } else {
                    return [...prevHistories, updatedUserHistory];
                }
            });
        });
        newConnection.on("DeleteUserHistory", (deletedUserHistory) => {
            if (deletedUserHistory.historyType !== 1) {
                return;
            }

            setAllUsersHistory((prevHistories) => {
                const updatedHistories = prevHistories.filter(history => history._id !== deletedUserHistory._id);
                return updatedHistories;
            });

            if (deletedUserHistory.movieDetail?.title) {
                showInfoToast(`History for "${deletedUserHistory.movieDetail.title}" has been deleted.`);
            }
        });

    }

    const fetchUserHistory = useCallback(() => {
        const baseRequest = {
            data: allUsers
        };
        return postRequestSyncPromise('UserHistory/GetSelectedUsersHistory', baseRequest)
            .then(data => {
                setAllUsersHistory(data.data);
            })
            .catch(error => {
                console.error("Error fetching user history:", error);
            });
    }, [allUsers]);

    useEffect(() => {
        const fetchData = async () => {
            await fetchAllUserNames();
        };
        fetchData();
    }, [fetchAllUserNames]);

    useEffect(() => {
        if (allUsers.length > 0) {
            fetchUserHistory();
        }
    }, [allUsers, fetchUserHistory]);

    //! HANDLE CARD CLICK
    const handleCardClick = (movie) => {
        setSelectedMovie(movie);
        setPopupVisible(true);
    };

    //! CLOSE POPUP
    const closePopup = () => {
        setPopupVisible(false);
        setSelectedMovie(null);
    };

    //! SLIDER SETTINGS
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                }
            }
        ]
    };

    //! RENDER SLIDER
    const renderSlider = () => {
        const sliderSettings = {
            ...settings,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            fade: true
        };

        return (
            <Slider {...sliderSettings}>
                {topMovies.map((movie) => (
                    <div key={movie.id}>
                        <SliderContent movieId={movie.id} postRequestSyncPromise={postRequestSyncPromise} allUsersHistory={allUsersHistory} />
                    </div>
                ))}
            </Slider>
        );
    };

    //! RENDER MOVIE CARDS
    const renderMovieCards = () => {
        return (
            <div className="movie-list">
                {searchedMovieDataSource.map(movie => (
                    <MovieCard key={movie.id} movie={movie} onClick={handleCardClick} />
                ))}
            </div>
        );
    };

    //! RENDER PAGE CONTENT
    const renderPageContent = () => {
        return (
            <div>
                <div className="search-bar">
                    <div className="control-row">
                        <input
                            type="text"
                            value={movieSearchTextboxValue}
                            onChange={(e) => setMovieSearchTextboxValue(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setIsSearchActive(true);
                                    SearchMovie(1);
                                }
                            }}
                            placeholder="Search Movie or TV Show"
                            className="movie-search-input"
                        />
                        <button
                            className="kaminari-button"
                            onClick={() => {
                                setIsSearchActive(true);
                                SearchMovie(1);
                            }}
                            aria-label="Search Movie"
                        >
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                        <button
                            className="kaminari-button"
                            onClick={() => setIsHistoryPopupVisible(true)}
                            aria-label="User History"
                        >
                            <FontAwesomeIcon icon={faHistory} />
                        </button>
                    </div>
                    <div className="control-row">
                        <GenreSelector
                            allMovieGenres={allMovieGenres}
                            selectedGenres={selectedGenres}
                            setSelectedGenres={setSelectedGenres}
                            className="genre-selector"
                        />
                        <FilterTypeSelect
                            filterType={filterType}
                            setFilterType={setFilterType}
                            className="filter-type-select"
                        />
                        <button
                            className="kaminari-button"
                            onClick={() => {
                                setIsSearchActive(false);
                                setMovieSearchTextboxValue("");
                                DiscoverMovie(1);
                            }}
                            aria-label="Discover Movie"
                        >
                            <FontAwesomeIcon icon={faFilm} />
                        </button>
                    </div>
                </div>

                <div className="movie-section">
                    <h2 className="section-title">Top 10 Popular Movies</h2>
                    {renderSlider()}
                </div>

                <div className="movie-section">
                    <h2 className="section-title">{isSearchActive ? "Search Results" : "Discover Movies"}</h2>
                    {renderMovieCards()}
                </div>
            </div>
        );
    };

    return (
        <div className="movie-container">
            <ToastContainer
                position='bottom-center'
            />
            {renderPageContent()}
            <div className="pagination-container">
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCountInfo}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    forcePage={currentPage - 1}
                />
            </div>
            {isPopupVisible && selectedMovie && (
                <MovieDetailTab
                    data={selectedMovie.id}
                    onClose={closePopup}
                    allUsersHistory={allUsersHistory}
                    setAllUsersHistory={setAllUsersHistory}
                />
            )}
            {isHistoryPopupVisible && (
                <UserHistoryPopup
                    isOpen={isHistoryPopupVisible}
                    toggle={() => setIsHistoryPopupVisible(false)}
                    allUsers={allUsers}
                    allUsersHistory={allUsersHistory}
                    setPopupVisible={setPopupVisible}
                    setSelectedMovie={setSelectedMovie}
                    postRequestSyncPromise={postRequestSyncPromise}
                    setAllUsersHistory={setAllUsersHistory}
                />
            )}
        </div>
    );
}