import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faUpload,
  faKey,
  faImage,
  faTimes,
  faLock,
  faUserEdit,
  faFileImport,
  faCloudUploadAlt,
  faEnvelope,
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import './Settings.scss';
import { usePostRequestSyncPromise } from '../../global/GlobalFetch';
import { parseString } from 'xml2js';

Modal.setAppElement('#root');
const showSuccessToast = (message) => {
  toast.dark(<div><FontAwesomeIcon icon={faCheckCircle} />{message}</div>);
};
const showErrorToast = (message) => {
  toast.dark(<div><FontAwesomeIcon icon={faTimesCircle} />{message}</div>);
};
const showWarningToast = (message) => {
  toast.dark(<div><FontAwesomeIcon icon={faExclamationTriangle} />{message}</div>);
};
const showInfoToast = (message) => {
  toast.dark(<div><FontAwesomeIcon icon={faInfoCircle} />{message}</div>);
};
export default function UserSettings({ isOpen, toggle }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newUserName, setNewUserName] = useState('');
  const [newEmail, setNewEmail] = useState('');

  const profileImageInputRef = useRef(null);
  const malAnimeInputRef = useRef(null);

  const postRequestSyncPromise = usePostRequestSyncPromise();

  const openProfileImageSelector = () => {
    profileImageInputRef.current.click();
  };

  const openMalAnimeSelector = () => {
    malAnimeInputRef.current.click();
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Image = reader.result.split(',')[1];
        const userCode = localStorage.getItem("userCode");

        const requestData = {
          data: [
            {
              Base64ImageString: base64Image,
              UserName: userCode,
            }
          ]
        };

        postRequestSyncPromise("Auth/ChangeUserImageAsync", requestData)
          .then(response => {
            if (response.type === 0) {
              showSuccessToast("Profile image updated successfully");
            } else {
              showErrorToast("Failed to update profile image : " + response.definitionLang);
            }
          })
          .catch(error => console.error("Error:", error));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangePassword = () => {
    let passwordInfo = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      userName: localStorage.getItem("userCode")
    };
    postRequestSyncPromise("Auth/ChangePassword", passwordInfo)
      .then(data => {
        if (data.type === 0) {
          showSuccessToast("Password changed successfully");
          setNewPassword('');
          setCurrentPassword('');
        }
        else {
          showErrorToast("Failed to change password : " + data.definitionLang);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleChangeUserName = () => {
    let userNameInfo = {
      userId: localStorage.getItem("userId"),
      currentUserName: localStorage.getItem("userCode"),
      newUserName: newUserName
    };
    postRequestSyncPromise("Auth/ChangeUserName", userNameInfo)
      .then(data => {
        if (data.type === 0) {
          showSuccessToast("User Name changed successfully");
          let userProfile = JSON.parse(localStorage.getItem("userData"));
          userProfile.userName = newUserName;

          localStorage.setItem("userData", JSON.stringify(userProfile));
          localStorage.setItem("userCode", newUserName);

          setNewPassword('');
          setCurrentPassword('');
          setNewUserName('');
        } else {
          showErrorToast("Failed to change user name : " + data.definitionLang);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        parseString(e.target.result, { explicitArray: false }, (err, result) => {
          if (err) {
            showErrorToast("Error parsing XML");
            console.error(err);
          } else {
            showInfoToast("Uploading anime list to server");
            sendDataToServer(result.myanimelist);
          }
        });
      };
      reader.readAsText(file);
    }
  };

  const sendDataToServer = (myAnimeListData) => {
    const formattedData = {
      myanimelist: myAnimeListData,
      username: localStorage.getItem("userCode"),
      userId: localStorage.getItem("userId"),
    };
    postRequestSyncPromise("UserAnimeProfile/FetchMyAnimeList", formattedData)
      .then(data => {
        if (data.type === 0) {
          showSuccessToast("Anime list successfully uploaded");
        } else {
          showErrorToast("Failed to upload anime list: " + data.definitionLang);
        }
      })
      .catch(error => {
        console.error("Error uploading anime list:", error);
        showErrorToast("Error uploading anime list");
      });
  };

  const handleChangeEmail = () => {
    const emailInfo = {
      userName: localStorage.getItem("userCode"),
      newMail: newEmail,
    };

    const baseRequest = {
      data: [emailInfo],
    }
    postRequestSyncPromise("Auth/ChangeUserMailAsync", baseRequest)
      .then((data) => {
        if (data.type === 0) {
          showSuccessToast("Email address changed successfully");
          setNewEmail('');
        } else {
          showErrorToast("Failed to change email address : " + data.definitionLang);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <ToastContainer
        position='bottom-center'
      />
      <Modal
        isOpen={isOpen}
        onRequestClose={toggle}
        className={{
          base: 'youtube-popup-profile',
          afterOpen: 'youtube-popup-profile_open',
          beforeClose: 'youtube-popup-profile_close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay_open',
          beforeClose: 'modal-overlay_close',
        }}
        closeTimeoutMS={300}
      >
        <button className="close-button" onClick={toggle}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="section-title">User Settings</h2>

        <div className="settings-container">
          <div className="settings-section">
            <h3 className="section-title">
              <FontAwesomeIcon icon={faKey} /> Change Password
            </h3>
            <div className="control-row">
              <input
                type="password"
                placeholder="Current Password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="kaminari-input"
              />
            </div>
            <div className="control-row">
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="kaminari-input"
              />
            </div>
            <div className="note-actions">
              <button
                onClick={handleChangePassword}
                className="kaminari-button"
              >
                <FontAwesomeIcon icon={faLock} />
              </button>
            </div>
          </div>

          <div className="settings-section">
            <h3 className="section-title">
              <FontAwesomeIcon icon={faUser} /> Change User Name
            </h3>
            <div className="control-row">
              <input
                type="text"
                placeholder="New User Name"
                value={newUserName}
                onChange={(e) => setNewUserName(e.target.value)}
                className="kaminari-input"
              />
            </div>
            <div className="note-actions">
              <button
                onClick={handleChangeUserName}
                className="kaminari-button"
              >
                <FontAwesomeIcon icon={faUserEdit} />
              </button>
            </div>
          </div>

          <div className="settings-section">
            <h3 className="section-title">
              <FontAwesomeIcon icon={faEnvelope} /> Update Email Address
            </h3>
            <div className="control-row">
              <input
                type="email"
                placeholder="New Email Address"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                className="kaminari-input"
              />
            </div>
            <div className="note-actions">
              <button
                onClick={handleChangeEmail}
                className="kaminari-button"
              >
                <FontAwesomeIcon icon={faUserEdit} />
              </button>
            </div>
          </div>

          <div className="settings-section">
            <h3 className="section-title">
              <FontAwesomeIcon icon={faUpload} /> Get Your MAL Animes
            </h3>
            <input
              type="file"
              style={{ display: 'none' }}
              ref={malAnimeInputRef}
              onChange={handleFileChange}
              accept=".xml"
            />
            <div className="note-actions">
              <button onClick={openMalAnimeSelector} className="kaminari-button">
                <FontAwesomeIcon icon={faFileImport} />
              </button>
            </div>
          </div>

          <div className="settings-section">
            <h3 className="section-title">
              <FontAwesomeIcon icon={faImage} /> Change Profile Image
            </h3>
            <input
              type="file"
              style={{ display: 'none' }}
              ref={profileImageInputRef}
              onChange={handleProfileImageChange}
              accept="image/*"
            />
            <div className="note-actions">
              <button onClick={openProfileImageSelector} className="kaminari-button">
                <FontAwesomeIcon icon={faCloudUploadAlt} />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}