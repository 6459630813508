import React from 'react';
import './UnderMaintenance.scss';
import { FaCog } from 'react-icons/fa';

function UnderMaintenance() {
  return (
    <div className="maintenance-container">
      <div className="neon-sign">
        <h1 className="neon-text">
          <span className="letter">B</span>
          <span className="letter flicker">a</span>
          <span className="letter off">k</span>
          <span className="letter">ı</span>
          <span className="letter flicker">m</span>
          <span className="letter">d</span>
          <span className="letter off">a</span>
          <span className="letter">y</span>
          <span className="letter off">ı</span>
          <span className="letter">z</span>
        </h1>
      </div>
      <p className="maintenance-message">
        Şu anda bu sayfa üzerinde çalışıyoruz. Lütfen daha sonra tekrar deneyin.
      </p>
      <div className="gear-container">
        <FaCog className="gear big-gear" />
        <FaCog className="gear small-gear" />
        <FaCog className="gear broken-gear" />
      </div>
    </div>
  );
}

export default UnderMaintenance;
