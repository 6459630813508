import React from 'react';
import './profile.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import UnderMaintenance from '../../components/Maintenance/UnderMaintenance';

export default function Profile() {
  return (
    <UnderMaintenance />
  );
}