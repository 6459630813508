import { useCallback, useContext } from 'react';
import { CONFIG } from '../CONFIG.js';
import { LoadingContext } from '../context/LoadingContext.js';

export function usePostRequestSyncPromise() {
  const { setIsLoading } = useContext(LoadingContext);

  const postRequestPromise = useCallback((MethodName, Data) => {
    const token = localStorage.getItem('token');
    const userCode = localStorage.getItem('userCode');
    const enrichedData = {
      ...Data,
      Current: [
        ...(Data?.Current || []),
        { Key: 'UserName', Value: userCode },
      ],
    };
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      fetch(CONFIG.BaseUrl + MethodName, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(enrichedData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }, [setIsLoading]);

  return postRequestPromise;
}