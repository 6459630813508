import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CONFIG } from '../../CONFIG.js';
import { AuthContext } from '../../context/AuthContext.js';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './Login.css';
import { ToastContainer, toast } from 'react-toastify';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
const showErrorToast = (message) => {
  toast.dark(<div><FontAwesomeIcon icon={faTimesCircle} />{message}</div>);
};
function Login() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    if (formData.email.length < 8 || formData.email.length > 30) {
      showErrorToast('Kullanıcı adı 8-30 karakter arasında olmalıdır');
      return;
    }
  
    if (formData.password.length < 8 || formData.password.length > 50) {
      showErrorToast('Şifre 8-50 karakter arasında olmalıdır');
      return;
    }
    e.preventDefault();
    setIsLoading(true);
    const data = JSON.stringify(formData);
    try {
      const response = await fetch(`${CONFIG.BaseUrl}Auth/LogIn`, {
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      });
      const result = await response.json();
      if (result.type === 0) {
        let userProfile = {
          userName: result.data[0].userName,
          avatarUrl: result.data[0].imageUrl,
          userId: result.data[0].mongoId,
        };

        const token = result.data[0].token;
        login(token);

        localStorage.setItem('userCode', result.data[0].userName);
        localStorage.setItem('userId', result.data[0].mongoId);
        localStorage.setItem('userData', JSON.stringify(userProfile));

        navigate('/');
      } else {
        showErrorToast((result.definitionLang || '').substring(0, 15) + '...');
      }
    } catch (error) {
      console.error('Giriş işlemi sırasında bir hata oluştu:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-page">
      <ToastContainer
        position='bottom-center'
      />
      <div className="login-container">
        <h2>Giriş Yap</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="text"
              name="email"
              placeholder="Kullanıcı Adı"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <div className="input-underline"></div>
          </div>
          <div className="input-group">
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Şifre"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <span className="password-toggle" onClick={toggleShowPassword}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
            <div className="input-underline"></div>
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? (
              <div className="spinner-border spinner-border-sm me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              'Giriş Yap'
            )}
          </button>
        </form>
        <p>
          Hesabın yok mu? <a href="/register">Kayıt Ol</a>
        </p>
      </div>
    </div>
  );
}
export default Login;