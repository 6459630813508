import React from 'react';
import './LoadingIndicator.scss';
import { GiLightningStorm } from 'react-icons/gi';

function LoadingIndicator() {
  return (
    <div className="loading-overlay">
      <div className="loading-content">
        <div className="loading-circle">
          <GiLightningStorm className="loading-icon" />
        </div>
        <p className="loading-text">Yükleniyor...</p>
      </div>
    </div>
  );
}

export default LoadingIndicator;
