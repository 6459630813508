import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import { usePostRequestSyncPromise } from "../../global/GlobalFetch";
import moment from 'moment';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faDoorOpen, faSync, faSignInAlt, faUsers, faArrowCircleLeft, faVideo, faArrowLeft, faArrowRight, faTimes, faCheckCircle, faTimesCircle, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './Together.css';
import * as signalR from '@microsoft/signalr';
import { ToastContainer, toast } from 'react-toastify';
import Draggable from 'react-draggable';
Modal.setAppElement('#root');
const showSuccessToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faCheckCircle} />{message}</div>);
};
const showErrorToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faTimesCircle} />{message}</div>);
};
const showWarningToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faExclamationTriangle} />{message}</div>);
};
const showInfoToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faInfoCircle} />{message}</div>);
};
export default function Together() {
    //! YT POPUP
    const [isYoutubePopupVisible, setYoutubePopupVisible] = useState(false);
    const [searchYoutube, setSearchYoutube] = useState("");
    const [youtubeSearchResults, setYoutubeSearchResults] = useState([]);
    const [nextPageToken, setNextPageToken] = useState("");
    const [prevPageToken, setPrevPageToken] = useState("");
    const [userImages, setUserImages] = useState([]);
    const signalConnection = useRef(null);

    const toggleYoutubePopup = () => {
        setYoutubePopupVisible(!isYoutubePopupVisible);
    };

    //! CURRENT TIME
    const [currentTime, setCurrentTime] = useState(moment());

    //! IS MOBILE
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    //! TOGETHER ROOMS STATE
    const [togetherRooms, setTogetherRooms] = useState([]);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(moment());
        }, 60000);
        return () => clearInterval(intervalId);
    }, []);

    //! INITIALIZE SIGNALR
    const initializeSignalR = async () => {
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl("https://api.kaminarivi.com.tr/togetherhub")
            .withAutomaticReconnect([1000, 2000, 3000, 5000, 10000])
            .build();

        async function start(connection) {
            try {
                await connection.start();
                console.log("SignalR Connected : ", connection.connectionId);
                localStorage.setItem("connectionId", connection.connectionId);
            } catch (error) {
                console.error(error);
                setTimeout(() => start(connection), 5000);
            }
        }
        start(newConnection);
        signalConnection.current = newConnection;
        newConnection.onreconnected(() => {
            showSuccessToast("Connection Reestablished.");
            postRequestSyncPromise("Together/EnterRoom", {
                userName: localStorage.getItem("userCode"),
                roomConnectionString: localStorage.getItem("roomConnectionString"),
                userConnectionId: newConnection.connectionId
            });
        });
        newConnection.onreconnected(() => {
            showSuccessToast("Connection Reestablished.");
        });
        newConnection.onclose(async (error) => {
            if (error) {
                console.error("SignalR connection closed with error: ", error);
            } else {
                console.log("SignalR connection closed.");
            }
            await postRequestSyncPromise("Together/LeaveRoom", {
                userName: localStorage.getItem("userCode"),
                roomConnectionString: localStorage.getItem("roomConnectionString"),
                userConnectionId: newConnection.connectionId
            });
        });
        newConnection.on("popError", (error) => {
            showErrorToast(error);
        });
        newConnection.on("userEnteredTheRoom", (object) => {
            setTogetherRooms(prevRooms => {
                return prevRooms.map(room => {
                    if (room.roomConnectionString === object.roomConnectionString) {
                        const activeUsers = room.activeUsers || [];
                        const updatedActiveUsers = [...new Set([...activeUsers, object.userName])];
                        return {
                            ...room,
                            activeUsers: updatedActiveUsers
                        };
                    }
                    return room;
                });
            });
        });
        newConnection.on("userLeftTheRoom", (object) => {
            showInfoToast(`${object.userName} left the room.`);

            setTogetherRooms(prevRooms => {
                return prevRooms.map(room => {
                    if (room.roomConnectionString === object.roomConnectionString) {
                        const activeUsers = room.activeUsers || [];
                        const updatedActiveUsers = activeUsers.filter(user => user !== object.userName);
                        return {
                            ...room,
                            activeUsers: updatedActiveUsers
                        };
                    }
                    return room;
                });
            });
        });
    }

    //! GRID HEIGHT
    const [gridHeight, setGridHeight] = useState(window.innerHeight);

    //! GLOBAL FETCH
    const postRequestSyncPromise = usePostRequestSyncPromise();

    //! IFRAME PANEL STATE
    const [showIFramePanel, setShowIFramePanel] = useState(false);
    const [iframeUrl, setIframeUrl] = useState("");
    const [userInput, setUserInput] = useState("");
    const [isURLValid, setIsURLValid] = useState(true);

    //! IS MOBILE
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    //! TOGETHER ROOMS FETCH
    const GetAllRooms = async () => {
        try {
            const data = await postRequestSyncPromise("Together/GetAllRooms");
            setTogetherRooms(data.data);
        } catch (error) {
            console.error(error);
        }
    };
    const fetchUserImages = async () => {
        try {
            const response = await postRequestSyncPromise("Auth/GetAllUserImages", null);
            if (response.type === 0) {
                setUserImages(response.data);
            }
        } catch (error) {
            console.error("API Error:", error);
        }
    }
    function closeIFramePanel() {
        const roomConnectionString = localStorage.getItem("roomConnectionString");
        signalConnection.current.invoke("LeaveRoom", localStorage.getItem("userCode"), roomConnectionString)
            .catch(err => console.error(err));

        setShowIFramePanel(false);
    }

    //! CREATE ROOM
    function CreateRoom() {
        const createRoomRequest = {
            share: "https://www.youtube.com/watch?v=f_WuRfuMXQw&ab_channel=SheeshBruhSubscribeBro",
            bg_color: "#363640",
            bg_opacity: "50"
        };

        const baseRequest = {
            RequestId: "unique_request_id",
            Sender: "client_create_room_request",
            Data: [createRoomRequest]
        };

        postRequestSyncPromise("Together/CreateRoom", baseRequest)
            .then(data => {
                GetAllRooms();
            })
            .catch(error => {
                console.error();
            });
    }
    function enterRoom(roomConnectionString) {
        localStorage.setItem("roomConnectionString", roomConnectionString);
        signalConnection.current.invoke("EnterRoom", localStorage.getItem("userCode"), roomConnectionString)
            .catch(err => console.error(err));

        setIframeUrl(roomConnectionString);
        setShowIFramePanel(true);
    }
    function handleUserInputChange(e) {
        const input = e.target.value;
        setUserInput(input);
    }

    //! USE EFFECT
    useEffect(() => {
        const initialize = async () => {
            await initializeSignalR();
            await GetAllRooms();
            await fetchUserImages();
        };
        initialize();

        const handleResize = () => {
            setGridHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //! SEARCH YOUTUBE ONCLICK
    function SearchYoutube() {
        const searchYoutubeRequest = {
            type: 'video',
            videoEmbeddable: true,
            pageToken: "",
            q: searchYoutube
        };

        postRequestSyncPromise("Together/YTSearchVideo", searchYoutubeRequest)
            .then(data => {
                if (data.type === 0) {
                    setYoutubeSearchResults(data.data[0].items);
                    setNextPageToken(data.data[0].nextPageInformation);
                    setPrevPageToken(data.data[0].prevPageToken);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const getDistinctUsers = (users) => {
        const uniqueUsers = [];
        users?.forEach((user) => {
            if (!uniqueUsers.some((u) => u.userName === user.userName)) {
                uniqueUsers.push(user);
            }
        });
        return uniqueUsers;
    };

    return (
        <div style={{ height: "100%" }}>
            <ToastContainer
                position='bottom-center'
            />
            {showIFramePanel ? (
                <div>
                    {isMobile ? (
                        <iframe
                            src={iframeUrl}
                            style={{
                                border: 'none',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%'
                            }}
                            allowFullScreen={true}
                        ></iframe>
                    ) : (
                        <iframe
                            src={iframeUrl}
                            width="100%"
                            height={gridHeight - 95
                            }
                            style={{ border: 'none' }}
                            allowFullScreen={true}
                        ></iframe>
                    )}

                    <Draggable>
                        <div className="draggable-bar">
                            <button
                                className="kaminari-button"
                                onClick={closeIFramePanel}
                            >
                                <FontAwesomeIcon icon={faArrowCircleLeft} />
                            </button>
                            <button
                                className="kaminari-button"
                                onClick={toggleYoutubePopup}
                            >
                                <FontAwesomeIcon icon={["fab", "youtube"]} />
                            </button>
                        </div>
                    </Draggable>
                    <div style={{ marginTop: '20px' }}>
                        {isYoutubePopupVisible && (
                            <div className="modal-overlay">
                                <div className="youtube-popup">
                                    <button className="close-button" onClick={toggleYoutubePopup}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                    <h2 className="note-title">YouTube'da Video Ara</h2>
                                    <div className="control-row">
                                        <input
                                            type="text"
                                            value={searchYoutube}
                                            onChange={(e) => setSearchYoutube(e.target.value)}
                                            placeholder="Search for a video on YouTube"
                                            className="kaminari-input"
                                        />
                                        <button className="kaminari-button" onClick={SearchYoutube}>
                                            <FontAwesomeIcon icon={"fa-brands fa-youtube"} />
                                        </button>
                                    </div>

                                    <div className="video-items-container">
                                        {youtubeSearchResults.map((video) => {
                                            const viewCount = new Intl.NumberFormat().format(video.statistics.viewCount);
                                            const likeCount = new Intl.NumberFormat().format(video.statistics.likeCount);

                                            return (
                                                <div key={video.id} className="video-item">
                                                    <div className="video-thumbnail-container">
                                                        <img
                                                            src={video.snippet.thumbnails.high.url}
                                                            alt={video.snippet.title}
                                                            className="video-thumbnail"
                                                        />
                                                    </div>
                                                    <div className="video-info">
                                                        <h6 className="video-title">{video.snippet.title}</h6>
                                                        <p className="video-channel">{video.snippet.channelTitle}</p>
                                                        <div className="video-stats">
                                                            <span>{viewCount} views</span>
                                                            <span>{likeCount} likes</span>
                                                        </div>
                                                    </div>
                                                    <div className="video-actions">
                                                        <button
                                                            className="kaminari-button"
                                                            onClick={() => {
                                                                const textToCopy = `https://www.youtube.com/watch?v=${video.id}`;
                                                                const copyTextToClipboard = async (text) => {
                                                                    if (navigator.clipboard && navigator.clipboard.writeText) {
                                                                        try {
                                                                            await navigator.clipboard.writeText(text);
                                                                            console.log('Text copied to clipboard');
                                                                        } catch (err) {
                                                                            console.error('Could not copy text: ', err);
                                                                        }
                                                                    } 
                                                                    else {
                                                                        console.warn('Clipboard API not available');
                                                                        const confirmed = window.confirm(
                                                                            'Your browser does not support automatic clipboard copying or web site is over http not https. Click OK to copy the link manually.'
                                                                        );
                                                                        if (confirmed) {
                                                                            const textArea = document.createElement('textarea');
                                                                            textArea.value = text;
                                                                            document.body.appendChild(textArea);
                                                                            textArea.select();
                                                                            try {
                                                                                document.execCommand('copy');
                                                                                console.log('Text copied to clipboard');
                                                                            } catch (err) {
                                                                                console.error('Fallback: Unable to copy', err);
                                                                            }
                                                                            document.body.removeChild(textArea);
                                                                        }
                                                                    }
                                                                };
                                                                copyTextToClipboard(textToCopy);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faVideo} />
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    </div>
                                    <div className="note-actions">
                                        <button className="kaminari-button" onClick={() => {
                                            const searchYoutubeRequest = {
                                                type: 'video',
                                                videoEmbeddable: true,
                                                pageToken: prevPageToken,
                                                q: searchYoutube
                                            };

                                            postRequestSyncPromise("Together/YTSearchVideo", searchYoutubeRequest)
                                                .then(data => {
                                                    if (data.type === 0) {
                                                        setYoutubeSearchResults(data.data[0].items);
                                                        setNextPageToken(data.data[0].nextPageInformation);
                                                        setPrevPageToken(data.data[0].prevPageToken);
                                                    }
                                                })
                                                .catch(error => {
                                                    console.error(error);
                                                });
                                        }}>
                                            <FontAwesomeIcon icon={faArrowLeft} />
                                        </button>
                                        <button className="kaminari-button" onClick={() => {
                                            const searchYoutubeRequest = {
                                                type: 'video',
                                                videoEmbeddable: true,
                                                pageToken: nextPageToken,
                                                q: searchYoutube
                                            };

                                            postRequestSyncPromise("Together/YTSearchVideo", searchYoutubeRequest)
                                                .then(data => {
                                                    if (data.type === 0) {
                                                        setYoutubeSearchResults(data.data[0].items);
                                                        setNextPageToken(data.data[0].nextPageInformation);
                                                        setPrevPageToken(data.data[0].prevPageToken);
                                                    }
                                                })
                                                .catch(error => {
                                                    console.error(error);
                                                });
                                        }}>
                                            <FontAwesomeIcon icon={faArrowRight} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="notes-container-together">
                    <div className="notes-header-together">
                        <h2 className="neon-text">Watch Together</h2>
                        <button className="kaminari-button" onClick={CreateRoom} disabled={togetherRooms.length >= 3}>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>

                    <div className="notes-input">
                        <input
                            type="text"
                            placeholder="Already Have Another Room"
                            value={userInput}
                            onChange={handleUserInputChange}
                            className="kaminari-input"
                        />
                        <button
                            className="kaminari-button"
                            onClick={() => {
                                if (isURLValid) {
                                    enterRoom(userInput);
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faSignInAlt} />
                        </button>
                    </div>

                    <div className="notes-list">
                        {togetherRooms.map((room) => {
                            const createDate = moment(room.createDate).format("YYYY-MM-DD HH:mm:ss");
                            const expirationDate = moment(room.createDate).add(24, 'hours');
                            const duration = moment.duration(expirationDate.diff(moment()));
                            const remainingTime = `${duration.hours()}h : ${duration.minutes()}m`;
                            const hasActiveUsers = room.activeUsers && room.activeUsers.length > 0;
                            const activeUsers = getDistinctUsers(room.activeUsers?.map(user => (
                                {
                                    userName: user,
                                    userImage: userImages.find(u => u.userName === user)?.base64ImageString
                                }
                            )));

                            return (
                                <div key={room._id} className="note-item">
                                    <div className="note-icon-container">
                                        <FontAwesomeIcon
                                            icon={faUsers}
                                            className={`note-icon ${hasActiveUsers ? 'note-icon-active' : ''}`}
                                        />
                                    </div>
                                    <div className="note-preview">
                                        <div className="note-info">
                                            <span className="note-label">Created:</span>
                                            <span className="note-value">{createDate}</span>
                                        </div>
                                        <div className="note-info">
                                            <span className="note-label">Remaining Time:</span>
                                            <span className="note-value">{remainingTime}</span>
                                        </div>
                                        <div className="note-info">
                                            <span className="note-label">Active Users:</span>
                                            <div className="active-users-panel">
                                                {activeUsers?.map((user) => (
                                                    <div key={user.userName} className="user-circle">
                                                        {user.userImage ? (
                                                            <img
                                                                src={`data:image/jpeg;base64,${user.userImage}`}
                                                                alt={user.userName}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'cover',
                                                                    borderRadius: '50%'
                                                                }}
                                                            />
                                                        ) : (
                                                            user.userName.charAt(0).toUpperCase()
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="note-actions">
                                        <button className="kaminari-button" onClick={GetAllRooms}>
                                            <FontAwesomeIcon icon={faSync} />
                                        </button>
                                        <button
                                            className="kaminari-button"
                                            onClick={() => enterRoom(room.roomConnectionString)}
                                        >
                                            <FontAwesomeIcon icon={faDoorOpen} />
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}