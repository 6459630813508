// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { CONFIG } from '../../CONFIG.js';
// import { FaEye, FaEyeSlash } from 'react-icons/fa';
// import './Register.css';

// function Register() {
//   const [formData, setFormData] = useState({ email: '', password: '', confirmPassword: '' });
//   const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false });
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (formData.password !== formData.confirmPassword) {
//       console.log('Şifreler eşleşmiyor');
//       return;
//     }

//     const { email, password } = formData;
//     const data = JSON.stringify({ email, password });

//     try {
//       const response = await fetch(`${CONFIG.BaseUrl}Auth/RegisterUser`, {
//         method: 'POST',
//         body: data,
//         headers: {
//           'Content-Type': 'application/json;charset=UTF-8',
//         },
//       });
//       const result = await response.json();
//       if (result.type === 0) {
//         navigate('/login');
//       } else {
//         console.log(result.definitionLang);
//       }
//     } catch (error) {
//       console.error('Kayıt işlemi sırasında bir hata oluştu:', error);
//     }
//   };

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const toggleShowPassword = (field) => {
//     setShowPassword({
//       ...showPassword,
//       [field]: !showPassword[field],
//     });
//   };

//   return (
//     <div className="register-page">
//       <div className="register-container">
//         <h2>Kayıt Ol</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="input-group">
//             <input
//               type="text"
//               name="email"
//               placeholder="Kullanıcı Adı"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//             <div className="input-underline"></div>
//           </div>
//           <div className="input-group">
//             <input
//               type={showPassword.password ? 'text' : 'password'}
//               name="password"
//               placeholder="Şifre"
//               value={formData.password}
//               onChange={handleChange}
//               required
//             />
//             <span className="password-toggle" onClick={() => toggleShowPassword('password')}>
//               {showPassword.password ? <FaEyeSlash /> : <FaEye />}
//             </span>
//             <div className="input-underline"></div>
//           </div>
//           <div className="input-group">
//             <input
//               type={showPassword.confirmPassword ? 'text' : 'password'}
//               name="confirmPassword"
//               placeholder="Şifre Tekrar"
//               value={formData.confirmPassword}
//               onChange={handleChange}
//               required
//             />
//             <span className="password-toggle" onClick={() => toggleShowPassword('confirmPassword')}>
//               {showPassword.confirmPassword ? <FaEyeSlash /> : <FaEye />}
//             </span>
//             <div className="input-underline"></div>
//           </div>
//           <button type="submit">Kayıt Ol</button>
//         </form>
//         <p>
//           Zaten hesabın var mı? <a href="/login">Giriş Yap</a>
//         </p>
//       </div>
//     </div>
//   );
// }

// export default Register;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CONFIG } from '../../CONFIG.js';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './Register.css';
import { ToastContainer, toast } from 'react-toastify';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';

function Register() {
  const [formData, setFormData] = useState({ email: '', password: '', confirmPassword: '' });
  const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const showErrorToast = (message) => {
    toast.dark(
      <div>
        <FontAwesomeIcon icon={faTimesCircle} /> {message}
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.email.length < 8 || formData.email.length > 30) {
      showErrorToast('Kullanıcı adı 8-30 karakter arasında olmalıdır');
      return;
    }

    if (formData.password.length < 8 || formData.password.length > 50) {
      showErrorToast('Şifre 8-50 karakter arasında olmalıdır');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      showErrorToast('Şifreler eşleşmiyor');
      return;
    }

    setIsLoading(true);

    const { email, password } = formData;
    const data = JSON.stringify({ email, password });

    try {
      const response = await fetch(`${CONFIG.BaseUrl}Auth/RegisterUser`, {
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      });
      const result = await response.json();
      if (result.type === 0) {
        navigate('/login');
      } else {
        showErrorToast((result.definitionLang || '').substring(0, 15) + '...');
      }
    } catch (error) {
      console.error('Kayıt işlemi sırasında bir hata oluştu:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const toggleShowPassword = (field) => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  return (
    <div className="register-page">
      <ToastContainer position="bottom-center" />
      <div className="register-container">
        <h2>Kayıt Ol</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="text"
              name="email"
              placeholder="Kullanıcı Adı"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <div className="input-underline"></div>
          </div>
          <div className="input-group">
            <input
              type={showPassword.password ? 'text' : 'password'}
              name="password"
              placeholder="Şifre"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <span className="password-toggle" onClick={() => toggleShowPassword('password')}>
              {showPassword.password ? <FaEyeSlash /> : <FaEye />}
            </span>
            <div className="input-underline"></div>
          </div>
          <div className="input-group">
            <input
              type={showPassword.confirmPassword ? 'text' : 'password'}
              name="confirmPassword"
              placeholder="Şifre Tekrar"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
            <span className="password-toggle" onClick={() => toggleShowPassword('confirmPassword')}>
              {showPassword.confirmPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
            <div className="input-underline"></div>
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? (
              <div className="spinner-border spinner-border-sm me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              'Kayıt Ol'
            )}
          </button>
        </form>
        <p>
          Zaten hesabın var mı? <a href="/login">Giriş Yap</a>
        </p>
      </div>
    </div>
  );
}

export default Register;
