import React, { useEffect, useContext, useState, useRef } from 'react';
import './Home.css';
import { GiLightningStorm } from 'react-icons/gi';
import {
  FaUserPlus,
  FaSignInAlt,
  FaVolumeUp,
  FaVolumeMute,
  FaPlay,
  FaPause,
  FaCamera,
  FaSkullCrossbones,
} from 'react-icons/fa';
import { AuthContext } from '../../context/AuthContext';
import backgroundVideo from '../../videos/vipitfight.mp4';

export default function Home() {
  const { isAuthenticated } = useContext(AuthContext);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [volume, setVolume] = useState(0.5);
  const [lastVolume, setLastVolume] = useState(0.5);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const revealElements = document.querySelectorAll('.reveal');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active');
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    revealElements.forEach((el) => {
      observer.observe(el);
    });
  }, []);

  useEffect(() => {
    let audioCtx;
    let analyser;
    let dataArray;
    let animationId;

    const setupAudioContext = () => {
      if (videoRef.current) {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        audioCtx = new AudioContext();
        const source = audioCtx.createMediaElementSource(videoRef.current);
        analyser = audioCtx.createAnalyser();
        source.connect(analyser);
        analyser.connect(audioCtx.destination);

        analyser.fftSize = 512;
        const bufferLength = analyser.frequencyBinCount;
        dataArray = new Uint8Array(bufferLength);

        drawVisualizer();
      }
    };

    const drawVisualizer = () => {
      const canvas = canvasRef.current;
      const canvasCtx = canvas.getContext('2d');
      const width = (canvas.width = canvas.offsetWidth);
      const height = (canvas.height = canvas.offsetHeight);

      const centerX = width / 2;
      const centerY = height / 2;
      const radius = Math.min(width, height) / 6;
      const maxBarHeight = radius / 2;

      const bars = 128;
      const step = Math.floor(dataArray.length / bars);

      const draw = () => {
        animationId = requestAnimationFrame(draw);
        analyser.getByteFrequencyData(dataArray);

        canvasCtx.clearRect(0, 0, width, height);

        // Begin wave path
        canvasCtx.beginPath();

        for (let i = 0; i <= bars; i++) {
          const angle = (i * Math.PI * 2) / bars;
          const frequency = dataArray[i * step] || 0;
          const barHeight = (frequency / 255) * maxBarHeight;
          const radiusWithWave = radius + barHeight;

          const x = centerX + Math.cos(angle) * radiusWithWave;
          const y = centerY + Math.sin(angle) * radiusWithWave;

          if (i === 0) {
            canvasCtx.moveTo(x, y);
          } else {
            canvasCtx.lineTo(x, y);
          }
        }

        canvasCtx.closePath();
        canvasCtx.strokeStyle = '#6a0dad';
        canvasCtx.lineWidth = 2;
        canvasCtx.stroke();

        // Draw central circle
        canvasCtx.beginPath();
        canvasCtx.arc(centerX, centerY, radius - 5, 0, 2 * Math.PI);
        canvasCtx.strokeStyle = '#4b0082';
        canvasCtx.lineWidth = 2;
        canvasCtx.stroke();
      };

      draw();
    };

    // Handle autoplay restrictions
    const handleResume = () => {
      if (audioCtx && audioCtx.state === 'suspended') {
        audioCtx.resume();
      }
    };

    document.addEventListener('click', handleResume);

    setupAudioContext();

    return () => {
      if (animationId) {
        cancelAnimationFrame(animationId);
      }
      if (audioCtx) {
        audioCtx.close();
      }
      document.removeEventListener('click', handleResume);
    };
  }, [videoRef]);

  const toggleMute = () => {
    if (isMuted) {
      setVolume(lastVolume);
      videoRef.current.volume = lastVolume;
    } else {
      setLastVolume(volume);
      setVolume(0);
      videoRef.current.volume = 0;
    }
    setIsMuted(!isMuted);
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    videoRef.current.volume = newVolume;

    if (newVolume === 0) {
      setIsMuted(true);
    } else {
      setIsMuted(false);
      setLastVolume(newVolume);
    }
  };

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const takeScreenshot = () => {
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0);

    const link = document.createElement('a');
    link.download = 'screenshot.png';
    link.href = canvas.toDataURL();
    link.click();
  };
  return (
    <div>
      {isAuthenticated ? (
       <div className="background-video-container">
       <video
         ref={videoRef}
         autoPlay
         loop
         muted={isMuted}
         playsInline
         className="video-background"
       >
         <source src={backgroundVideo} type="video/mp4" />
       </video>

       <div className="welcome-overlay">
         <div className="welcome-brand-section">
           <h1 className="glitch-text">
             Kaminari<span className="highlight-text">VI</span>
           </h1>
           <p className="tagline">UNLEASH THE UNDERGROUND</p>
         </div>

         <div className="audio-visualizer-container">
           <canvas ref={canvasRef} className="audio-visualizer"></canvas>
           <div className="icon-overlay">
             <FaSkullCrossbones className="center-icon" />
           </div>
         </div>
       </div>

       <div className="video-controls">
         <button onClick={togglePlay} className="control-btn">
           {isPlaying ? <FaPause /> : <FaPlay />}
         </button>

         <div className="volume-container">
           <button className="control-btn volume-btn" onClick={toggleMute}>
             {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
           </button>

           <div className="volume-slider-container">
             <input
               type="range"
               min="0"
               max="1"
               step="0.01"
               value={volume}
               onChange={handleVolumeChange}
               className="volume-slider"
             />
           </div>
         </div>

         <button onClick={takeScreenshot} className="control-btn">
           <FaCamera />
         </button>
       </div>
     </div>
      ) : (
        <div className="home-container">
          <section className="hero-section">
            <div className="overlay">
              <div className="logo-container">
                <GiLightningStorm className="logo-icon" />
                <h1 className="hero-title">Kaminari</h1>
              </div>
              <p className="hero-subtitle">
                Anime, film ve dizileri keşfedin, arkadaşlarınızla birlikte izleyin ve deneyimlerinizi paylaşın.
              </p>
              <div className="button-container">
                <a href="/register" className="hero-button">
                  <FaUserPlus className="button-icon" />
                  Katıl
                </a>
                <a href="/login" className="hero-button">
                  <FaSignInAlt className="button-icon" />
                  Giriş Yap
                </a>
              </div>
            </div>
          </section>

          <section className="features-section reveal">
            <h2 className="section-title">Özellikler</h2>
            <div className="features-container">
              <div className="feature">
                <h3>Puanlama ve Kaydetme</h3>
                <p>İzlediğiniz içerikleri puanlayın, favorilerinize ekleyin ve daha sonra tekrar izleyin.</p>
              </div>
              <div className="feature">
                <h3>Birlikte İzleme</h3>
                <p>Arkadaşlarınızla senkronize bir şekilde ortak player üzerinden video izleyin.</p>
              </div>
              <div className="feature">
                <h3>Not Alma</h3>
                <p>Önemli anları not alın ve daha sonra bu notlara kolayca erişin.</p>
              </div>
              <div className="feature">
                <h3>Kişisel Profil</h3>
                <p>Geçmişinizi ve izleme raporlarınızı profil sayfanızda görüntüleyin.</p>
              </div>
            </div>
          </section>

          <section className="about-section reveal">
            <h2>Hakkımızda</h2>
            <p>
              Kaminari, anime, film ve dizi severler için tasarlanmış bir platformdur. Burada yeni içerikler keşfedebilir, arkadaşlarınızla birlikte izleyebilir ve deneyimlerinizi paylaşabilirsiniz.
            </p>
          </section>

          <section className="gallery-section reveal">
            <h2>Keşfet</h2>
            <div className="gallery">
              <div
                className="gallery-item"
                style={{ backgroundImage: `url(${require('../../Images/Anime.jpg')})` }}
              ></div>
              <div
                className="gallery-item"
                style={{ backgroundImage: `url(${require('../../Images/Movie.jpg')})` }}
              ></div>
              <div
                className="gallery-item"
                style={{ backgroundImage: `url(${require('../../Images/Series.jpg')})` }}
              ></div>
              <div
                className="gallery-item"
                style={{ backgroundImage: `url(${require('../../Images/Cinema.jpg')})` }}
              ></div>
              <div
                className="gallery-item"
                style={{ backgroundImage: `url(${require('../../Images/PopCorn.jpg')})` }}
              ></div>
              <div
                className="gallery-item"
                style={{ backgroundImage: `url(${require('../../Images/friends.jpg')})` }}
              ></div>
            </div>
          </section>

          <section className="testimonial-section reveal">
            <h2>Kullanıcı Yorumları</h2>
            <div className="testimonials">
              <div className="testimonial">
                <p>
                  "Kaminari sayesinde netflix, amazon prime, disney plus gibi hizmet kalitesi dusuk sirketlere para odemekten kurtuldum!"
                </p>
                <h4>- Ibo</h4>
              </div>
              <div className="testimonial">
                <p>
                  "Istedigim dizi, film, animeleri izleyip kaydedebiliyor, arkadaslarimin gecmisinden kendime yenilerini bulabiliyorum.!"
                </p>
                <h4>- Ahmet</h4>
              </div>
              <div className="testimonial">
                <p>
                  "Notlarimi burada yaziyor boylece windows 365'in crackli versiyonlarindaki virusler ile ugrasmiyor, para odemekten de kurtuluyorum."
                </p>
                <h4>- Efe</h4>
              </div>
            </div>
          </section>

          <section className="faq-section reveal">
            <h2>Sıkça Sorulan Sorular</h2>
            <div className="faq">
              <div className="faq-item">
                <h3>Kaminari nedir?</h3>
                <p>Kaminari, anime, film ve dizi izleyebileceğiniz, arkadaşlarınızla birlikte izleme deneyimi yaşayabileceğiniz bir platformdur.</p>
              </div>
              <div className="faq-item">
                <h3>Üyelik ücretli mi?</h3>
                <p>Hayır, Kaminari tamamen ücretsizdir.</p>
              </div>
              <div className="faq-item">
                <h3>Arkadaşlarımla nasıl birlikte izleyebilirim?</h3>
                <p>Birlikte izleme özelliğine Together sayfasindan ulasabilirsiniz.</p>
              </div>
              <div className="faq-item">
                <h3>Notlarımı nasıl kaydedebilirim?</h3>
                <p>Notes sayfasi uzerinden not alma ozelligine erisebilirsiniz.</p>
              </div>
              <div className="faq-item">
                <h3>İzleme geçmişime nasıl erişebilirim?</h3>
                <p>Movie ve TvShow sayfalarinda arama butonunun saginda bulunan butona tiklayarak erisebilirsiniz.</p>
              </div>
            </div>
          </section>

          <section className="call-to-action reveal">
            <h2>Deneyiminizi Bir Üst Seviyeye Taşıyın</h2>
            <p>Kaminari'ye katılın ve topluluğumuzun bir parçası olun.</p>
            <a href="/register" className="cta-button">Hemen Başlayın</a>
          </section>

          <footer className="footer">
            <p>&copy; {new Date().getFullYear()} Kaminari. Tüm hakları saklıdır.</p>
          </footer>
        </div>
      )}
    </div>
  );
}