import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import CustomNavbar from './components/CustomNavbar/CustomNavbar';
import Home from './pages/home/home';
import KaminariCord from './pages/kaminaricord/KaminariCord';
import Movie from './pages/movie/movie';
import TvShow from './pages/tvShow/TvShow';
import Notes from './pages/notes/notes';
import Profile from './pages/profile/profile';
import Together from './pages/together/together';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Anime from './pages/anime/anime';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthContext } from './context/AuthContext';
import { LoadingProvider, LoadingContext } from './context/LoadingContext';
import LoadingIndicator from './components/Indicators/LoadingIndicator';

function PrivateRoute({ children }) {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated ? children : <Navigate to="/login" />;
}

function AppContent() {
  const { isAuthenticated } = useContext(AuthContext);
  const { isLoading } = useContext(LoadingContext);

  return (
    <Router>
      <div className="app-container">
        {isLoading && <LoadingIndicator />}
        <header className="app-header">
          {isAuthenticated && <CustomNavbar />}
        </header>
        <main className="app-body">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/"
              element={
                  <Home />
              }
            />
            <Route path="/anime" element={<PrivateRoute><Anime /></PrivateRoute>} />
            <Route path="/kaminaricord" element={<PrivateRoute><KaminariCord /></PrivateRoute>} />
            <Route path="/movie" element={<PrivateRoute><Movie /></PrivateRoute>} />
            <Route path="/tvshow" element={<PrivateRoute><TvShow /></PrivateRoute>} />
            <Route path="/notes" element={<PrivateRoute><Notes /></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route path="/together" element={<PrivateRoute><Together /></PrivateRoute>} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

function App() {
  return (
    <LoadingProvider>
      <AppContent />
    </LoadingProvider>
  );
}

export default App;