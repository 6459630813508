import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSlash, faMicrophone, faMicrophoneSlash, faHeadphones, faVolumeMute, faCheckCircle, faExclamationTriangle, faInfoCircle, faTimesCircle, faVideo, faVideoSlash, faBell, faBellSlash, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Row, Col, InputGroup } from 'reactstrap';
import './KaminariCord.css';
import { usePostRequestSyncPromise } from '../../global/GlobalFetch';
import * as signalR from '@microsoft/signalr';
import { motion, useAnimation } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import { CONFIG } from '../../CONFIG';
import { debounce } from 'lodash';
const showSuccessToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faCheckCircle} />{message}</div>);
};
const showErrorToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faTimesCircle} />{message}</div>);
};
const showWarningToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faExclamationTriangle} />{message}</div>);
};
const showInfoToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faInfoCircle} />{message}</div>);
};
const Inline = Quill.import('blots/inline');
class MentionBlot extends Inline {
    static create(userName) {
        let node = super.create();
        node.setAttribute('data-username', userName);
        node.style.color = '#7B61FF';
        node.style.fontWeight = 'bold';
        node.classList.add('mention-animate');
        return node;
    }

    static formats(node) {
        return node.getAttribute('data-username');
    }
}
MentionBlot.blotName = 'mention';
MentionBlot.tagName = 'span';
MentionBlot.className = 'mention';
Quill.register(MentionBlot);
const MessagesPanel = ({ messages, onSendMessage, userImages, allUserNames }) => {
    const [messageContent, setMessageContent] = useState('');
    const messagesPanelRef = useRef(null);
    const quillRef = useRef(null);
    const suggestionListRef = useRef(null);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [showScrollToBottom, setShowScrollToBottom] = useState(false);
    const [userSuggestions, setUserSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [caretPosition, setCaretPosition] = useState(0);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);
    const [suggestionPosition, setSuggestionPosition] = useState({ top: 0, left: 0 });
    const [visibleMessages, setVisibleMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const dateIndexRef = useRef(0);
    const currentUser = JSON.parse(localStorage.getItem('userData')).userName;

    const datesArray = useMemo(() => {
        const uniqueDates = [...new Set(messages.map(msg => new Date(msg.sentAt).toDateString()))];
        uniqueDates.sort((a, b) => new Date(b) - new Date(a));
        return uniqueDates;
    }, [messages]);

    const sanitizeMessageContent = (content) => {
        return content.replace(/<p>(<br>|\s)*<\/p>/g, '').trim();
    };

    const getMentionedUsernames = () => {
        const editor = quillRef.current.getEditor();
        const contents = editor.getContents();
        const mentionedUsernames = [];
        contents.ops.forEach(op => {
            if (op.insert && op.attributes && op.attributes.mention) {
                mentionedUsernames.push(op.attributes.mention);
            }
        });
        return mentionedUsernames;
    };

    const handleSuggestionInsert = (userName) => {
        const editor = quillRef.current.getEditor();
        const cursorPosition = editor.getSelection()?.index || 0;
        let startIndex = cursorPosition - 1;
        while (startIndex >= 0) {
            const char = editor.getText(startIndex, 1);
            if (/\s/.test(char) || char === '\n') break;
            startIndex--;
        }
        startIndex = Math.max(0, startIndex + 1);
        editor.deleteText(startIndex, cursorPosition - startIndex);
        editor.insertText(startIndex, `@${userName}`, { mention: userName });
        editor.formatText(startIndex + `@${userName}`.length, 1, 'mention', false);
        editor.insertText(startIndex + `@${userName}`.length, ' ', 'mention', false);
        editor.setSelection(startIndex + `@${userName}`.length + 1);
        setShowSuggestions(false);
        setSelectedSuggestionIndex(0);
        editor.focus();
    };

    const handleSendMessage = () => {
        const sanitizedContent = sanitizeMessageContent(messageContent);
        if (sanitizedContent === '') return;
        const MAX_MESSAGE_SIZE = 32000;
        const messageSize = new Blob([messageContent]).size;
        if (messageSize > MAX_MESSAGE_SIZE) {
            alert('Mesajınız çok büyük. Lütfen daha kısa bir mesaj gönderin.');
            return;
        }
        const mentionedUsers = [...messageContent.matchAll(/@(\w+)/g)].map((match) => match[1]);
        if (mentionedUsers.length > 0) {
            const requestObject = {
                requestId: "unique-request-id",
                sender: "current-user",
                data: [
                    {
                        senderUserName: localStorage.getItem("userCode"),
                        receiverUserNames: mentionedUsers,
                    },
                ],
                current: [{ Key: 'UserName', Value: localStorage.getItem("userCode") }],
            };
            fetch(CONFIG.BaseUrl + 'Notification/SendNotificationViaEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(requestObject),
            })
                .then((response) => {
                    if (response.ok) {
                        showInfoToast('Bildirim gönderildi.');
                    } else {
                        showErrorToast('Bildirim gönderilirken bir hata oluştu.');
                    }
                })
                .catch((error) => {
                    showErrorToast('Bildirim gönderilirken bir hata oluştu.');
                    console.error('Error sending notification:', error);
                });
        }
        if (isAtBottom) {
            scrollToBottom();
        }
        onSendMessage(messageContent.trimEnd());
        setMessageContent('');
    };

    const handleKeyDown = (e) => {
        if (showSuggestions) {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setSelectedSuggestionIndex((prevIndex) =>
                    prevIndex < userSuggestions.length - 1 ? prevIndex + 1 : 0
                );
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                setSelectedSuggestionIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : userSuggestions.length - 1
                );
            } else if (e.key === 'Enter') {
                e.preventDefault();
                handleSuggestionInsert(userSuggestions[selectedSuggestionIndex]);
            } else if (e.key === 'Escape') {
                e.preventDefault();
                setShowSuggestions(false);
                setSelectedSuggestionIndex(0);
                quillRef.current.focus();
            }
        } else {
            if (e.key === 'Escape') {
                e.preventDefault();
                setShowSuggestions(false);
                setSelectedSuggestionIndex(0);
                quillRef.current.focus();
            } else if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
            }
        }
    };

    const handleKeyUp = (e) => {
        const editor = quillRef.current.getEditor();
        const position = editor.getSelection()?.index || 0;
        setCaretPosition(position);
        const content = editor.getText(0, position);
        const lastWord = content.split(/\s/).pop();
        if (lastWord.startsWith("@")) {
            const query = lastWord.slice(1).toLowerCase();
            const mentionedUsernames = getMentionedUsernames();
            const suggestions = allUserNames
                .filter(name => name.toLowerCase().includes(query))
                .sort((a, b) => {
                    const aMentioned = mentionedUsernames.includes(a);
                    const bMentioned = mentionedUsernames.includes(b);
                    if (aMentioned && !bMentioned) return 1;
                    if (!aMentioned && bMentioned) return -1;
                    return 0;
                });
            setUserSuggestions(suggestions);
            setShowSuggestions(suggestions.length > 0);
            setSelectedSuggestionIndex(0);
            const cursorBounds = editor.getBounds(position);
            setSuggestionPosition({
                top: cursorBounds.bottom,
                left: cursorBounds.left,
            });
        } else {
            setShowSuggestions(false);
            setSelectedSuggestionIndex(0);
        }
    };

    useEffect(() => {
        if (showSuggestions && suggestionListRef.current) {
            suggestionListRef.current.focus();
        }
    }, [showSuggestions]);

    const handleSuggestionKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedSuggestionIndex((prevIndex) =>
                prevIndex < userSuggestions.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedSuggestionIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : userSuggestions.length - 1
            );
        } else if (e.key === 'Enter') {
            e.preventDefault();
            handleSuggestionInsert(userSuggestions[selectedSuggestionIndex]);
        } else if (e.key === 'Escape') {
            setShowSuggestions(false);
            setSelectedSuggestionIndex(0);
            quillRef.current.focus();
        }
    };

    const handleSuggestionClick = (userName) => {
        handleSuggestionInsert(userName);
    };

    const loadInitialMessages = () => {
        const lastThreeDays = datesArray.slice(0, 3);
        const initialMessages = messages.filter(msg => {
            const msgDate = new Date(msg.sentAt).toDateString();
            return lastThreeDays.includes(msgDate);
        });
        setVisibleMessages(initialMessages);
        dateIndexRef.current = 3;
    };

    const loadMoreMessages = () => {
        if (dateIndexRef.current >= datesArray.length || loading) {
            return;
        }
        setLoading(true);
        const dateToLoad = datesArray[dateIndexRef.current];
        const newMessages = messages.filter(msg => {
            const msgDate = new Date(msg.sentAt).toDateString();
            return msgDate === dateToLoad;
        });
        setVisibleMessages(prevMessages => [...newMessages, ...prevMessages]);
        dateIndexRef.current += 1;
        setLoading(false);
    };

    useEffect(() => {
        if (messages.length === 0) {
            return;
        }
        loadInitialMessages();
    }, [messages]);

    const handleScroll = () => {
        if (messagesPanelRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = messagesPanelRef.current;
            const threshold = 100;
            const atBottom = scrollHeight - (scrollTop + clientHeight) <= threshold;
            setIsAtBottom(atBottom);
            setShowScrollToBottom(!atBottom);
            if (scrollTop < threshold && !loading) {
                loadMoreMessages();
            }
        }
    };

    const scrollToBottom = () => {
        if (messagesPanelRef.current) {
            messagesPanelRef.current.scrollTop = messagesPanelRef.current.scrollHeight;
            setIsAtBottom(true);
            setShowScrollToBottom(false);
        }
    };

    useEffect(() => {
        if (isAtBottom) {
            scrollToBottom();
        }
    }, [visibleMessages]);

    const groupMessagesByDate = (messages) => {
        const groups = {};
        messages.forEach(message => {
            const date = new Date(message.sentAt);
            const dateKey = date.toLocaleDateString('tr-TR', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                weekday: 'long'
            });
            if (!groups[dateKey]) {
                groups[dateKey] = [];
            }
            groups[dateKey].push(message);
        });
        return groups;
    };

    const DateSeparator = ({ date }) => (
        <div className="date-separator">
            <div className="date-line"></div>
            <div className="date-text">{date}</div>
            <div className="date-line"></div>
        </div>
    );

    const quillModules = {
        toolbar: [
            [{ 'font': [] }, { 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'header': [1, 2, 3, false] }, { 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'video'],
            ['clean']
        ],
    };

    const quillFormats = [
        'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
        'color', 'background',
        'header', 'align',
        'list', 'bullet', 'indent',
        'link', 'video',
        'mention'
    ];

    return (
        <div className="kaminari-messages-container">
            <div
                className="kaminari-messages-panel"
                ref={messagesPanelRef}
                onScroll={handleScroll}
            >
                {loading && (
                    <div className="loading-indicator">
                        <div className="loading-spinner"></div>
                    </div>
                )}
                {Object.entries(groupMessagesByDate(visibleMessages)).map(([date, dateMessages]) => (
                    <div key={date}>
                        <DateSeparator date={date} />
                        {dateMessages.map((message, index) => {
                            const isOwnMessage = message.senderId === currentUser;
                            const userImage = userImages.find(img => img.userName === message.senderId);
                            const senderInitial = message.senderId
                                ? message.senderId.charAt(0).toUpperCase()
                                : '?';
                            const sentAt = message.sentAt
                                ? new Date(message.sentAt).toLocaleString('tr-TR', {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })
                                : 'Unknown';

                            return (
                                <div key={index} className={`message-row ${isOwnMessage ? 'own-message' : 'other-message'}`}>
                                    {!isOwnMessage && (
                                        <div className="user-circle">
                                            {userImage ? (
                                                <img
                                                    src={`data:image/jpeg;base64,${userImage.base64ImageString}`}
                                                    alt={message.senderId}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            ) : (
                                                senderInitial
                                            )}
                                        </div>
                                    )}
                                    <div className="message-bubble-container">
                                        {!isOwnMessage && (
                                            <div className="sender-name">{message.senderId}</div>
                                        )}
                                        <div className="message-bubble">
                                            <div
                                                className={`message-content`}
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(message.messageContent, {
                                                        ADD_TAGS: ['iframe'],
                                                        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'src', 'width', 'height', 'style'],
                                                        FORBID_TAGS: ['img'],
                                                        ALLOW_DATA_ATTR: false,
                                                        ALLOWED_URI_REGEXP: /^(https?:)?\/\/(www\.youtube\.com\/|player\.vimeo\.com\/)/
                                                    })
                                                }}
                                            />
                                            <div className="message-time">{sentAt}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
            {showScrollToBottom && (
                <button
                    className="scroll-to-bottom-button"
                    onClick={(e) => {
                        e.preventDefault();
                        scrollToBottom();
                    }}
                >
                    <FontAwesomeIcon icon={faArrowDown} />
                </button>
            )}
            <div className="message-input-container">
                {showSuggestions && (
                    <div
                        className="user-suggestions"
                        ref={suggestionListRef}
                        tabIndex="0"
                        onKeyDown={handleSuggestionKeyDown}
                        style={{ top: suggestionPosition.top, left: suggestionPosition.left }}
                    >
                        {userSuggestions.map((userName, index) => {
                            const userImage = userImages.find(img => img.userName === userName);
                            return (
                                <div
                                    key={index}
                                    className={`suggestion-item ${index === selectedSuggestionIndex ? 'selected' : ''}`}
                                    onClick={() => handleSuggestionClick(userName)}
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                        handleSuggestionClick(userName);
                                    }}
                                >
                                    <div className="suggestion-user-circle">
                                        {userImage ? (
                                            <img
                                                src={`data:image/jpeg;base64,${userImage.base64ImageString}`}
                                                alt={userName}
                                                className="suggestion-user-image"
                                            />
                                        ) : (
                                            <span className="suggestion-user-initial">
                                                {userName.charAt(0).toUpperCase()}
                                            </span>
                                        )}
                                    </div>
                                    <span className="suggestion-username">@{userName}</span>
                                </div>
                            );
                        })}
                    </div>
                )}
                <InputGroup>
                    <ReactQuill
                        ref={quillRef}
                        theme="snow"
                        value={messageContent}
                        onChange={(content) => {
                            const sanitizedContent = sanitizeMessageContent(content);
                            setMessageContent(sanitizedContent);
                        }}
                        onKeyUp={handleKeyUp}
                        onKeyDown={handleKeyDown}
                        className="message-input-editor"
                        modules={quillModules}
                        placeholder="Mesajınızı yazın..."
                        formats={quillFormats}
                    />
                </InputGroup>
            </div>
        </div>
    );
};
const LeftPanel = ({ userImages, noiseSuppressionOn, setNoiseSuppressionOn, videoOn, setVideoOn, setRemoteStreams, peerConnections, localStream, micOn, setMicOn, soundOn, setSoundOn, channels, selectedTextChannel, setSelectedTextChannel, selectedVoiceChannel, setSelectedVoiceChannel, lastSelectedChannel, setLastSelectedChannel, user, userJoinChannelAction, userLeftChannelAction, voiceChannelUsers, fetchMessagesForChannel }) => {
    const getChannelIcon = (channelType) => {
        return channelType === 1 ? 'fa-microphone' : 'fa-comments';
    };
    const handleChannelClick = (channel) => {
        // Kullanıcı yeni bir sesli kanal seçiyorsa, önceki sesli kanaldan ayrılmasını sağla
        if (channel.channelType === 1) {
            // Kullanıcı zaten bu sesli kanalda mı? Eğer öyleyse işlem yapma ve çık
            if (selectedVoiceChannel === channel.roomId) {
                return;
            }

            // Önceki sesli kanaldan ayrıl
            if (selectedVoiceChannel) {
                userLeftChannelAction(selectedVoiceChannel);
            }

            // Yeni seçilen sesli kanala katıl
            setSelectedVoiceChannel(channel.roomId);
            userJoinChannelAction(channel.roomId);
        }

        // Metin kanalı seçiliyorsa ses kanalından çıkma, sadece metin kanalını seç
        if (channel.channelType !== 1) {
            setSelectedTextChannel(channel.roomId);
            fetchMessagesForChannel(channel.roomId)
        }

        // Son seçilen kanalı güncelle
        setLastSelectedChannel(channel);
    };

    const setlastselectedChannelToFalse = () => {
        setLastSelectedChannel(false);
    }

    return (
        <Col md="3" className="kaminari-left">
            <div className="kaminari-header" onClick={setlastselectedChannelToFalse}>
                <div className="neon-sign-header">
                    <span className="neon-text-header">K</span>
                    <span className="neon-text-header">a</span>
                    <span className="neon-text-header">m</span>
                    <span className="neon-text-header">i</span>
                    <span className="neon-text-header">n</span>
                    <span className="neon-text-header">a</span>
                    <span className="neon-text-header">r</span>
                    <span className="neon-text-header">i</span>
                    <span className="neon-text-cord-header">Cord</span>
                </div>
            </div>
            <div className="kaminari-channels">
                <ul>
                    {channels.map((channel) => (
                        <li
                            key={channel.roomId}
                            className={`kaminari-channel-list-item 
                                ${selectedTextChannel === channel.roomId ? 'text-selected' : ''} 
                                ${selectedVoiceChannel === channel.roomId ? 'voice-selected' : ''} 
                                ${lastSelectedChannel?.roomId === channel.roomId ? 'active' : ''}`}
                            onClick={() => handleChannelClick(channel)}
                        >
                            <i className={`fas ${getChannelIcon(channel.channelType)}`} style={{ marginRight: '10px' }}></i>
                            {channel.channelName}

                            {channel.channelType === 1 && (
                                <div className="kaminari-channel-users-panel">
                                    <div className="kaminari-panel-header">Active Users</div>
                                    <div className="kaminari-channel-users">
                                        {(voiceChannelUsers[channel.roomId] || []).length > 0 ? (
                                            (voiceChannelUsers[channel.roomId] || []).map(({ userName }, index) => {
                                                const userImage = userImages.find(img => img.userName === userName);

                                                return (
                                                    <div key={index} className="user-circle">
                                                        {userImage ? (
                                                            <img
                                                                src={`data:image/jpeg;base64,${userImage.base64ImageString}`}
                                                                alt={userName}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'cover',
                                                                    borderRadius: '50%'
                                                                }}
                                                            />
                                                        ) : (
                                                            userName[0]
                                                        )}
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="no-users">No active users</div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="kaminari-button-panel">
                <div className="kaminari-button" onClick={() => setNoiseSuppressionOn(!noiseSuppressionOn)}>
                    <FontAwesomeIcon icon={noiseSuppressionOn ? faBellSlash : faBell} />
                </div>
                <div className={`kaminari-button ${micOn ? 'active' : ''}`} onClick={() => setMicOn(!micOn)}>
                    <FontAwesomeIcon icon={micOn ? faMicrophone : faMicrophoneSlash} />
                </div>
                <div className={`kaminari-button ${soundOn ? 'active' : ''}`} onClick={() => {
                    const audioElements = document.querySelectorAll('audio');
                    audioElements.forEach(audio => {
                        audio.muted = !soundOn;
                    });
                    setSoundOn(!soundOn);
                }}>
                    <FontAwesomeIcon icon={soundOn ? faHeadphones : faVolumeMute} />
                </div>
                <div className={`kaminari-button ${videoOn ? 'active' : ''}`} onClick={() => setVideoOn(!videoOn)}>
                    <FontAwesomeIcon icon={videoOn ? faVideo : faVideoSlash} />
                </div>
                <div className="kaminari-button kaminari-button-danger" onClick={() => {
                    if (selectedVoiceChannel) {
                        userLeftChannelAction(selectedVoiceChannel);
                        setSelectedVoiceChannel(null);

                        // Close all peer connections
                        Object.values(peerConnections.current).forEach(pc => pc.close());
                        peerConnections.current = {};

                        // Clear remote streams
                        setRemoteStreams({});
                    }
                }}>
                    <FontAwesomeIcon icon={faPhoneSlash} />
                </div>
            </div>
        </Col >
    );
};
const VoicePanel = ({ usersInVoiceChannel, remoteStreams, localStream, myConnectionId, userImages, onOpenVolumeMenu, mutedUsers, soundOn, userVolumes, detailedVolume, fineVolume }) => {
    const userCount = usersInVoiceChannel.length;
    const gridClass = userCount <= 4 ? 'grid-2x2' : 'grid-3x3';

    const computeFinalVolume = (connectionId) => {
        const mainVolume = userVolumes[connectionId] || 100;
        const isMuted = mutedUsers[connectionId];
        if (isMuted) return 0;
        if (mainVolume < 10 && detailedVolume) {
            return (mainVolume * fineVolume) / 100;
        } else {
            return mainVolume / 100;
        }
    };

    return (
        <div className={`voice-panel-container ${gridClass}`}>
            {usersInVoiceChannel.map(({ userName, connectionId }) => {
                const isLocal = connectionId === myConnectionId;
                const stream = isLocal ? localStream : remoteStreams[connectionId];
                const userImage = userImages.find((img) => img.userName === userName);
                const hasVideo = stream && stream.getVideoTracks().length > 0;
                const finalVolume = computeFinalVolume(connectionId);
                const isMuted = mutedUsers[connectionId] || !soundOn;

                return (
                    <VoiceUser
                        key={connectionId}
                        userName={userName}
                        connectionId={connectionId}
                        stream={stream}
                        isLocal={isLocal}
                        userImage={userImage?.base64ImageString}
                        hasVideo={hasVideo}
                        onOpenVolumeMenu={onOpenVolumeMenu}
                        isMuted={isMuted}
                        finalVolume={finalVolume}
                    />
                );
            })}
        </div>
    );
};
const VoiceUser = React.memo(({ connectionId, userName, stream, isLocal, userImage, onOpenVolumeMenu, isMuted, finalVolume }) => {
    const videoRef = useRef(null);
    const [volume, setVolume] = useState(0);
    const [hasVideo, setHasVideo] = useState(false);

    useEffect(() => {
        if (videoRef.current && stream && hasVideo) {
            videoRef.current.srcObject = stream;
        }
    }, [stream, hasVideo]);

    useEffect(() => {
        let audioContext, analyser, source, dataArray, rafId;

        const setupAudioAnalysis = () => {
            if (stream && stream.getAudioTracks().length > 0) {
                audioContext = new (window.AudioContext || window.webkitAudioContext)();
                analyser = audioContext.createAnalyser();
                source = audioContext.createMediaStreamSource(stream);
                source.connect(analyser);
                analyser.fftSize = 256;
                dataArray = new Uint8Array(analyser.frequencyBinCount);

                const detectVolume = () => {
                    analyser.getByteFrequencyData(dataArray);
                    const avgVolume = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
                    setVolume(avgVolume);
                    rafId = requestAnimationFrame(detectVolume);
                };

                detectVolume();
            }
        };

        setupAudioAnalysis();

        const handleTrackEvent = () => {
            setHasVideo(stream.getVideoTracks().length > 0);
            if (audioContext) {
                audioContext.close();
            }
            if (rafId) {
                cancelAnimationFrame(rafId);
            }
            setupAudioAnalysis();
        };

        if (stream) {
            setHasVideo(stream.getVideoTracks().length > 0);
            stream.addEventListener('addtrack', handleTrackEvent);
            stream.addEventListener('removetrack', handleTrackEvent);
        }

        return () => {
            if (rafId) cancelAnimationFrame(rafId);
            if (audioContext) audioContext.close();
            if (stream) {
                stream.removeEventListener('addtrack', handleTrackEvent);
                stream.removeEventListener('removetrack', handleTrackEvent);
            }
        };
    }, [stream]);

    useEffect(() => {
        if (videoRef.current && hasVideo) {
            videoRef.current.muted = isMuted;
            videoRef.current.volume = finalVolume;
        }
    }, [isMuted, finalVolume, hasVideo]);

    const volumeLevel = Math.min(volume / 100, 1);

    return (
        <div
            className="voice-user-card"
            style={{
                boxShadow: hasVideo ? `0 0 10px rgba(26, 115, 232, ${volumeLevel})` : 'none',
                border: hasVideo ? `2px solid rgba(26, 115, 232, ${volumeLevel})` : 'none',
            }}
            onContextMenu={(e) => {
                console.log(e)
                console.log(connectionId)
                e.preventDefault();
                onOpenVolumeMenu(e.clientX, e.clientY, connectionId);
            }}
        >
            {hasVideo ? (
                <div className="video-container">
                    <video
                        ref={videoRef}
                        autoPlay
                        playsInline
                        muted={isLocal || isMuted}
                        className="voice-user-video"
                    />
                </div>
            ) : (
                <div
                    className="voice-user-placeholder"
                    style={{
                        transform: `scale(${1 + volumeLevel * 0.4})`,
                    }}
                >
                    {userImage ? (
                        <img
                            src={`data:image/jpeg;base64,${userImage}`}
                            alt={userName}
                            className="voice-user-image"
                        />
                    ) : (
                        <div className="voice-user-initial">
                            {userName.charAt(0).toUpperCase()}
                        </div>
                    )}
                </div>
            )}
            {isMuted && (
                <div className="muted-icon">
                    <i className="fas fa-volume-mute"></i>
                </div>
            )}
            <div className="voice-user-name">{userName}</div>
        </div>
    );
});
export default function KaminariCord() {
    const postRequestSyncPromise = usePostRequestSyncPromise();
    const [micOn, setMicOn] = useState(true);
    const [soundOn, setSoundOn] = useState(true);
    const [channels, setChannels] = useState([]);
    const [selectedTextChannel, setSelectedTextChannel] = useState(null);
    const [selectedVoiceChannel, setSelectedVoiceChannel] = useState(null);
    const [lastSelectedChannel, setLastSelectedChannel] = useState(null);
    const [voiceChannelUsers, setVoiceChannelUsers] = useState({});
    const [isChannelsLoaded, setIsChannelsLoaded] = useState(false);
    const [noiseSuppressionOn, setNoiseSuppressionOn] = useState(false);
    const [messages, setMessages] = useState([]);
    const signalConnection = useRef(null);
    let localStream = useRef(null);
    const peerConnections = useRef({});
    const [remoteStreams, setRemoteStreams] = useState({});
    const [videoOn, setVideoOn] = useState(false);
    const [userImages, setUserImages] = useState([]);
    const [allUserNames, setAllUserNames] = useState([]);
    const [userVolumes, setUserVolumes] = useState({});
    const [volumeMenuVisible, setVolumeMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [targetConnectionId, setTargetConnectionId] = useState(null);
    const containerRef = useRef(null);
    const [detailedVolume, setDetailedVolume] = useState(false);
    const [fineVolume, setFineVolume] = useState(1.0);
    const [mutedUsers, setMutedUsers] = useState({});
    const user = JSON.parse(localStorage.getItem('userData'));

    const joinSoundRef = useRef(null);
    const leaveSoundRef = useRef(null);

    useEffect(() => {
        joinSoundRef.current = new Audio("/sounds/UserJoined.mp3");
        joinSoundRef.current.volume = 0.5;

        leaveSoundRef.current = new Audio("/sounds/UserLeft.mp3");
        leaveSoundRef.current.volume = 0.5;
    }, []);


    let servers = {
        iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:stun1.l.google.com:19302' },
            { urls: 'stun:stun2.l.google.com:19302' },
            { urls: 'stun:stun3.l.google.com:19302' },
            { urls: 'stun:stun4.l.google.com:19302' },
            { urls: 'stun:stun.stunprotocol.org:3478' },
            { urls: 'stun:stun.voiparound.com' },
            { urls: 'stun:stun.voipbuster.com' },
            { urls: 'stun:stun.voipstunt.com' },
            { urls: 'stun:stun.services.mozilla.com' },
        ]
    };

    const sendMessageToGroup = async (messageContent) => {
        if (!signalConnection || !lastSelectedChannel) return;

        const message = {
            RoomId: lastSelectedChannel.roomId,
            SenderId: user.userName,
            MessageContent: messageContent,
            SentAt: new Date()
        };
        const request = { data: [message] };

        try {
            await signalConnection.current.invoke("SendMessageToGroup", request);
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    const fetchMessagesForChannel = async (channelId) => {
        const reqData = {
            data: [
                { roomId: channelId }
            ]
        };
        try {
            const response = await postRequestSyncPromise("CordMessage/GetAllMessagesByChannel", reqData);
            if (response.type === 0 && response.data) {
                setMessages(response.data);
            } else {
                console.error("Failed to load messages");
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };

    const fetchUserImages = async () => {
        try {
            const response = await postRequestSyncPromise("Auth/GetAllUserImages", null);
            if (response.type === 0) {
                setUserImages(response.data);
            }
        } catch (error) {
            console.error("API Error:", error);
        }
    }

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (lastSelectedChannel?.roomId) {
                userLeftChannelAction(lastSelectedChannel.roomId);
            }
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [lastSelectedChannel]);

    const userJoinChannelAction = (selectedRoomId) => {
        const reqData = {
            data: [
                {
                    roomId: selectedRoomId,
                    userToJoin: user.userName,
                    userConnectionId: JSON.parse(localStorage.getItem('connectionId'))
                }
            ]
        }
        postRequestSyncPromise("CordChannel/UserJoinChannel", reqData)
            .then(response => {
                if (response.type === 0) {
                    console.log("User joined channel successfully.");
                }
            })
            .catch(error => {
                console.error("API Error:", error);
            });
    };

    const userLeftChannelAction = (selectedRoomId) => {
        const reqData = {
            data: [
                {
                    roomId: selectedRoomId,
                    userToLeave: user.userName,
                    userConnectionId: JSON.parse(localStorage.getItem('connectionId'))
                }
            ]
        }
        postRequestSyncPromise("CordChannel/UserLeftChannel", reqData)
            .then(response => {
                if (response.type === 0) {
                    console.log("User left channel successfully.");
                }
            })
            .catch(error => {
                console.error("API Error:", error);
            });
    };

    const initializeSignalR = async () => {
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl("https://api.kaminarivi.com.tr/chatHub")
            .withAutomaticReconnect([1000, 2000, 3000, 5000, 10000])
            .build();

        async function start(connection) {
            try {
                await connection.start();
                console.log("connected");

                const connectionId = connection.connectionId;
                localStorage.setItem('connectionId', connectionId);

                const textChannelIds = channels
                    .filter(channel => channel.channelType !== 1)
                    .map(channel => channel.roomId);

                const joinChannelRequests = textChannelIds.map(roomId => ({
                    connectionId: connectionId,
                    RoomId: roomId
                }));

                const baseRequest = {
                    Data: joinChannelRequests
                };

                await connection.invoke("JoinTextChannel", baseRequest)
                    .then(() => console.log("JoinTextChannel success"))
                    .catch(err => console.error("JoinTextChannel error: ", err));

            } catch (err) {
                console.error("Connection error: ", err);
                setTimeout(() => start(connection), 5000);
            }
        }

        start(newConnection);
        signalConnection.current = newConnection;

        newConnection.onreconnecting(() => {
            showErrorToast("Connection Lost. Reconnecting...");
        });
        newConnection.onreconnected(() => {
            showSuccessToast("Connection Reestablished.");
        });
        newConnection.onclose(async (error) => {
            if (error) {
                console.error("SignalR connection closed with error: ", error);
            } else {
                console.log("SignalR connection closed.");
            }
            showErrorToast("Connection Closed.");
            try {
                const response = await postRequestSyncPromise("CordChannel/ClearUserFromChannels", {
                    data: [
                        localStorage.getItem('userCode')
                    ]
                });
                if (response.status === 200) {
                    console.log("User cleared from channels successfully.");
                } else {
                    console.log(`Failed to clear user from channels. Status code: ${response.status}`);
                }
            } catch (error) {
                console.error("Error clearing user from channels:", error);
            }
        });

        const setupPeerConnection = (peerConnection, remoteConnectionId) => {
            peerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                    signalConnection.current.invoke('SendIceCandidate', remoteConnectionId, signalConnection.current.connectionId, event.candidate);
                }
            };

            peerConnection.ontrack = (event) => {
                setRemoteStreams((prevStreams) => ({
                    ...prevStreams,
                    [remoteConnectionId]: event.streams[0],
                }));
            };

            peerConnection.onnegotiationneeded = async () => {
                try {
                    const offer = await peerConnection.createOffer();
                    await peerConnection.setLocalDescription(offer);
                    signalConnection.current.invoke('SendOffer', remoteConnectionId, signalConnection.current.connectionId, offer);
                } catch (error) {
                    console.error('Error during negotiation:', error);
                }
            };
        };

        signalConnection.current.on("userJoinedToServer", async (joinedServerInfo) => {
            const { roomId, userToJoin, connectionId, allUsers } = joinedServerInfo;
            setVoiceChannelUsers(prevState => ({
                ...prevState,
                [roomId]: allUsers
            }));

            console.log("User Joined Server :", allUsers)

            const myConnectionId = signalConnection.current.connectionId;
            const amIInThisChannel = allUsers.some(u => u.connectionId === myConnectionId);

            if (amIInThisChannel && connectionId !== myConnectionId) {
                joinSoundRef.current.play().catch(err => console.error("Error playing join sound:", err));
            }

            if (connectionId === myConnectionId) {
                const otherUsers = allUsers.filter(user => user.connectionId !== myConnectionId);
                if (otherUsers.length > 0) {
                    otherUsers.forEach(async (user) => {
                        const remoteConnectionId = user.connectionId;
                        const peerConnection = new RTCPeerConnection(servers);
                        setupPeerConnection(peerConnection, remoteConnectionId);
                        if (localStream.current && localStream.current.getTracks && localStream.current.getTracks().length > 0) {
                            localStream.current.getTracks().forEach((track) => {
                                peerConnection.addTrack(track, localStream.current);
                            });
                        }
                        peerConnections.current[remoteConnectionId] = peerConnection;
                        const offer = await peerConnection.createOffer();
                        await peerConnection.setLocalDescription(offer);
                        signalConnection.current.invoke("SendOffer", remoteConnectionId, myConnectionId, offer);
                    });
                }
            }
        });

        signalConnection.current.on('ReceiveOffer', async (senderConnectionId, offer) => {
            let peerConnection = peerConnections.current[senderConnectionId];

            if (!peerConnection) {
                peerConnection = new RTCPeerConnection(servers);
                peerConnections.current[senderConnectionId] = peerConnection;
                setupPeerConnection(peerConnection, senderConnectionId);

                if (localStream.current && localStream.current.getTracks && localStream.current.getTracks().length > 0) {
                    localStream.current.getTracks().forEach((track) => {
                        const senderExists = peerConnection.getSenders().find((s) => s.track && s.track.id === track.id);
                        if (!senderExists) {
                            peerConnection.addTrack(track, localStream.current);
                        }
                    });
                }
            }

            try {
                await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
                const answer = await peerConnection.createAnswer();
                await peerConnection.setLocalDescription(answer);
                signalConnection.current.invoke('SendAnswer', senderConnectionId, signalConnection.current.connectionId, answer);
            } catch (error) {
                console.error('Error handling offer:', error);
            }
        });

        signalConnection.current.on('ReceiveAnswer', async (senderConnectionId, answer) => {
            const peerConnection = peerConnections.current[senderConnectionId];
            if (peerConnection) {
                try {
                    await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
                } catch (error) {
                    console.error('Error setting remote description:', error);
                }
            }
        });

        signalConnection.current.on("ReceiveIceCandidate", async (senderConnectionId, candidate) => {
            const peerConnection = peerConnections.current[senderConnectionId];
            if (peerConnection) {
                try {
                    await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
                } catch (error) {
                    console.error('Error adding received ICE candidate', error);
                }
            }
        });

        signalConnection.current.on("userLeftToServer", (leftServerInfo) => {
            const { roomId, connectionId, allUsers } = leftServerInfo;
            setVoiceChannelUsers(prevState => ({
                ...prevState,
                [roomId]: allUsers
            }));

            const myConnectionId = signalConnection.current.connectionId;
            const amIInThisChannel = allUsers.some(u => u.connectionId === myConnectionId);

            if (amIInThisChannel && connectionId !== myConnectionId) {
                leaveSoundRef.current.play().catch(err => console.error("Error playing leave sound:", err));
            }

            if (peerConnections.current[connectionId]) {
                peerConnections.current[connectionId].close();
                delete peerConnections.current[connectionId];
            }

            setRemoteStreams(prevStreams => {
                const newStreams = { ...prevStreams };
                delete newStreams[connectionId];
                return newStreams;
            });
        });

        newConnection.on("getConnectionId", (connectionId) => {
            localStorage.setItem('connectionId', JSON.stringify(connectionId));
        });

        newConnection.on("popError", (error) => {
            showErrorToast(error);
        });

        newConnection.on("popSuccess", (message) => {
            showSuccessToast(message);
        });

        newConnection.on("sendMessageToGroup", (newMessage) => {
            setMessages((prevMessages) => [...prevMessages, newMessage]);
        });
    };

    const updateLocalStream = useCallback(async () => {
        if (!localStream.current) {
            localStream.current = new MediaStream();
        }

        let needsRenegotiation = false;

        // Mikrofon
        const audioTracks = localStream.current.getAudioTracks();
        if (micOn && soundOn) {
            const constraints = {
                audio: {
                    noiseSuppression: noiseSuppressionOn,
                    echoCancellation: true,
                    autoGainControl: true,
                },
            };

            if (audioTracks.length === 0) {
                try {
                    const audioStream = await navigator.mediaDevices.getUserMedia(constraints);
                    const audioTrack = audioStream.getAudioTracks()[0];
                    localStream.current.addTrack(audioTrack);
                    Object.values(peerConnections.current).forEach((pc) => {
                        pc.addTrack(audioTrack, localStream.current);
                    });
                    needsRenegotiation = true;
                } catch (error) {
                    console.error('Error accessing audio devices:', error);
                }
            } else {
                try {
                    const audioStream = await navigator.mediaDevices.getUserMedia(constraints);
                    const newAudioTrack = audioStream.getAudioTracks()[0];
                    localStream.current.removeTrack(audioTracks[0]);
                    localStream.current.addTrack(newAudioTrack);
                    Object.values(peerConnections.current).forEach((pc) => {
                        const sender = pc.getSenders().find((s) => s.track && s.track.kind === 'audio');
                        if (sender) {
                            sender.replaceTrack(newAudioTrack);
                        }
                    });
                    needsRenegotiation = true;
                } catch (error) {
                    console.error('Error replacing audio track:', error);
                }
            }
        } else {
            // Mikrofon kapalı
            if (audioTracks.length > 0) {
                audioTracks.forEach((track) => (track.enabled = false));
            }
        }

        // Kamera
        const videoTracks = localStream.current.getVideoTracks();
        if (videoOn) {
            if (videoTracks.length === 0) {
                try {
                    const videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
                    const videoTrack = videoStream.getVideoTracks()[0];
                    localStream.current.addTrack(videoTrack);
                    Object.values(peerConnections.current).forEach((pc) => {
                        pc.addTrack(videoTrack, localStream.current);
                    });
                    needsRenegotiation = true;
                } catch (error) {
                    console.error('Error accessing video devices:', error);
                }
            } else {
                videoTracks.forEach((track) => (track.enabled = true));
            }
        } else {
            if (videoTracks.length > 0) {
                videoTracks.forEach((track) => (track.enabled = false));
            }
        }

        if (needsRenegotiation) {
            Object.entries(peerConnections.current).forEach(async ([connectionId, pc]) => {
                try {
                    const offer = await pc.createOffer();
                    await pc.setLocalDescription(offer);
                    signalConnection.current.invoke('SendOffer', connectionId, signalConnection.current.connectionId, offer);
                } catch (error) {
                    console.error('Error during renegotiation:', error);
                }
            });
        }
    }, [micOn, videoOn, soundOn, noiseSuppressionOn]);

    useEffect(() => {
        updateLocalStream();
    }, [updateLocalStream, noiseSuppressionOn]);

    useEffect(() => {
        const getAllActiveChannels = async () => {
            try {
                const response = await postRequestSyncPromise("CordChannel/GetAllActiveCordChannels", null);
                if (response.type === 0) {
                    const channels = response.data;
                    setChannels(channels);
                    const updatedVoiceChannelUsers = {};
                    channels.forEach(channel => {
                        if (channel.roomId && channel.activeUsers) {
                            updatedVoiceChannelUsers[channel.roomId] = channel.activeUsers;
                        }
                    });
                    setVoiceChannelUsers(updatedVoiceChannelUsers);
                    setIsChannelsLoaded(true);
                }
            } catch (error) {
                console.error("API Error:", error);
            }
        };
        getAllActiveChannels();
        fetchUserImages();
        fetchAllUserNames();
    }, []);

    useEffect(() => {
        return () => {
            if (localStream.current) {
                localStream.current.getTracks().forEach((track) => track.stop());
            }
            Object.values(peerConnections.current).forEach((pc) => pc.close());
            peerConnections.current = {};
            if (signalConnection.current) {
                signalConnection.current.stop();
            }
        };
    }, []);

    useEffect(() => {
        if (isChannelsLoaded) {
            initializeSignalR();
        }
    }, [isChannelsLoaded]);

    const fetchAllUserNames = useCallback(async () => {
        return postRequestSyncPromise('Auth/GetAllUserNames', {})
            .then(data => {
                setAllUserNames(data.data);
            })
            .catch(error => {
                console.error("Error fetching all user names:", error);
            });
    }, []);

    const handleVolumeChange = (connectionId, newVolumePercent) => {
        setUserVolumes(prev => ({ ...prev, [connectionId]: newVolumePercent }));
    };

    const handleFineVolumeChange = (connectionId, newFineValue) => {
        setFineVolume(newFineValue);
        // fineVolume artık sadece çok düşük mainVolume durumlarında devreye girecek.
        // render sırasında uygulanacak.
    };

    const onOpenVolumeMenu = (clientX, clientY, connectionId) => {
        if (containerRef.current) {
            const x = clientX - 625;
            const y = clientY - 100;
            setMenuPosition({ x, y });
            setTargetConnectionId(connectionId);
            setVolumeMenuVisible(true);
        }
    };

    const handleMuteToggle = (connectionId) => {
        setMutedUsers(prev => {
            const newValue = !prev[connectionId];
            return { ...prev, [connectionId]: newValue };
        });
    };

    const computeFinalVolume = (connectionId) => {
        const mainVolume = userVolumes[connectionId] || 100;
        const isMuted = mutedUsers[connectionId];
        if (isMuted) return 0;

        if (mainVolume < 10 && detailedVolume) {
            return (mainVolume * fineVolume) / 100;
        } else {
            return mainVolume / 100;
        }
    };
    return (
        <div className="kaminari-container" ref={containerRef}>
            <Row className="kaminari-row" style={{ height: '100%' }}>
                <ToastContainer position='bottom-center' />

                {Object.keys(remoteStreams).map((connectionId) => {
                    const finalVolume = computeFinalVolume(connectionId);
                    return (
                        <audio
                            key={connectionId}
                            autoPlay
                            playsInline
                            ref={(audio) => {
                                if (audio) {
                                    audio.srcObject = remoteStreams[connectionId];
                                    audio.muted = mutedUsers[connectionId] || !soundOn;
                                    audio.volume = finalVolume;
                                }
                            }}
                        ></audio>
                    );
                })}

                <LeftPanel
                    micOn={micOn}
                    setMicOn={setMicOn}
                    soundOn={soundOn}
                    setSoundOn={setSoundOn}
                    channels={channels}
                    selectedTextChannel={selectedTextChannel}
                    setSelectedTextChannel={setSelectedTextChannel}
                    selectedVoiceChannel={selectedVoiceChannel}
                    setSelectedVoiceChannel={setSelectedVoiceChannel}
                    lastSelectedChannel={lastSelectedChannel}
                    setLastSelectedChannel={setLastSelectedChannel}
                    user={user}
                    userJoinChannelAction={userJoinChannelAction}
                    userLeftChannelAction={userLeftChannelAction}
                    voiceChannelUsers={voiceChannelUsers}
                    fetchMessagesForChannel={fetchMessagesForChannel}
                    localStream={localStream}
                    peerConnections={peerConnections}
                    setRemoteStreams={setRemoteStreams}
                    videoOn={videoOn}
                    setVideoOn={setVideoOn}
                    noiseSuppressionOn={noiseSuppressionOn}
                    setNoiseSuppressionOn={setNoiseSuppressionOn}
                    userImages={userImages}
                />
                <Col md="9" className="kaminari-right" style={{ height: '100%' }}>
                    {!lastSelectedChannel && (
                        <div className="no-channel-selected">
                            <div className="neon-sign">
                                <span className="neon-text">K</span>
                                <span className="neon-text">a</span>
                                <span className="neon-text">m</span>
                                <span className="neon-text">i</span>
                                <span className="neon-text">n</span>
                                <span className="neon-text">a</span>
                                <span className="neon-text">r</span>
                                <span className="neon-text">i</span>
                                <span className="neon-text-cord">Cord</span>
                            </div>
                            <p className="neon-subtext">Please select a channel to view its content.</p>
                        </div>
                    )}
                    {lastSelectedChannel && lastSelectedChannel.channelType !== 1 && (
                        <div className="text-channel-content">
                            <MessagesPanel
                                messages={messages}
                                onSendMessage={sendMessageToGroup}
                                currentUser={user.userName}
                                userImages={userImages}
                                allUserNames={allUserNames}
                                postRequestSyncPromise={postRequestSyncPromise}
                            />
                        </div>
                    )}
                    {lastSelectedChannel && lastSelectedChannel.channelType === 1 && (
                        <div className="voice-channel-selected" style={{ height: "100%", width: "100%" }}>
                            <VoicePanel
                                usersInVoiceChannel={voiceChannelUsers[lastSelectedChannel.roomId] || []}
                                remoteStreams={remoteStreams}
                                localStream={localStream.current}
                                myConnectionId={signalConnection.current.connectionId}
                                userImages={userImages}
                                onOpenVolumeMenu={onOpenVolumeMenu}
                                mutedUsers={mutedUsers}
                                soundOn={soundOn}
                                userVolumes={userVolumes}
                                detailedVolume={detailedVolume}
                                fineVolume={fineVolume}
                            />
                        </div>
                    )}
                    {volumeMenuVisible && targetConnectionId && (
                        <div
                            className="volume-menu"
                            style={{
                                position: 'absolute',
                                top: menuPosition.y,
                                left: menuPosition.x,
                            }}
                            onMouseLeave={() => setVolumeMenuVisible(false)}
                        >
                            <div className="volume-menu-item">
                                <label>Volume: {userVolumes[targetConnectionId] || 100}%</label>
                                <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={userVolumes[targetConnectionId] || 100}
                                    onChange={(e) => handleVolumeChange(targetConnectionId, parseInt(e.target.value, 10))}
                                />
                            </div>

                            <div className="menu-separator"></div>

                            <div className="menu-option" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <span>Detailed volume</span>
                                <input
                                    type="checkbox"
                                    checked={detailedVolume}
                                    onChange={(e) => {
                                        setDetailedVolume(e.target.checked);
                                        if (!e.target.checked) {
                                            setFineVolume(1.0);
                                        }
                                    }}
                                />
                            </div>

                            {detailedVolume && (userVolumes[targetConnectionId] || 100) < 10 && (
                                <>
                                    <div className="volume-menu-item">
                                        <label>Fine Tune</label>
                                        <input
                                            type="range"
                                            min="0"
                                            max="1"
                                            step="0.01"
                                            value={fineVolume}
                                            onChange={(e) => handleFineVolumeChange(targetConnectionId, parseFloat(e.target.value))}
                                        />
                                        <span>{(fineVolume * 100).toFixed(0)}%</span>
                                    </div>
                                </>
                            )}

                            <div className="menu-separator"></div>

                            <div
                                className="menu-option"
                                onClick={() => {
                                    handleMuteToggle(targetConnectionId);
                                }}
                            >
                                {mutedUsers[targetConnectionId] ? "Unmute User" : "Mute User"}
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
}