import React, { useEffect, useState, useCallback, useRef} from 'react';
import { Row, Col, Modal, ModalBody } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import './anime.css';
import { usePostRequestSyncPromise } from '../../global/GlobalFetch';
import {
    FaSearch,
    FaSlidersH,
    FaSyncAlt,
    FaFilm,
    FaBroadcastTower,
    FaLeaf,
} from 'react-icons/fa';
import {
    faCheckCircle,
    faPlayCircle,
    faPauseCircle,
    faTimesCircle,
    faClock,
    faStar,
    faTimes,
    faPlay
} from '@fortawesome/free-solid-svg-icons';
import ReactSlider from 'react-slider';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
const AnimeRating = ({ anime, onClose, onSubmit }) => {
    const [selectedStatus, setSelectedStatus] = useState(
        anime.my_status || 'Plan to Watch'
    );
    const [userScore, setUserScore] = useState(
        anime.my_score ? parseInt(anime.my_score) : 0
    );
    const [hoverScore, setHoverScore] = useState(0);

    const animeStatuses = [
        { value: 'Completed', label: 'Completed', icon: 'fa-check-circle' },
        { value: 'Watching', label: 'Watching', icon: 'fa-play-circle' },
        { value: 'On-Hold', label: 'On-Hold', icon: 'fa-pause-circle' },
        { value: 'Dropped', label: 'Dropped', icon: 'fa-times-circle' },
        { value: 'Plan to Watch', label: 'Plan to Watch', icon: 'fa-clock' },
    ];

    const handleSubmit = () => {
        const updatedAnime = {
            ...anime,
            my_status: selectedStatus,
            my_score: userScore,
        };
        onSubmit(updatedAnime);
    };

    return (
        <div className="anime-rating-overlay">
            <div className="anime-rating-modal">
                <div className="anime-rating-header">
                    <h2>{anime.title}</h2>
                    <button className="close-button" onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </button>
                </div>
                <div className="anime-rating-content">
                    <div className="anime-rating-image">
                        <img src={anime.images.jpg.large_image_url} alt={anime.title} />
                    </div>
                    <div className="anime-rating-controls">
                        <div className="status-selector">
                            {animeStatuses.map((status) => (
                                <button
                                    key={status.value}
                                    className={`status-button ${selectedStatus === status.value ? 'active' : ''
                                        }`}
                                    onClick={() => setSelectedStatus(status.value)}
                                >
                                    <i className={`fas ${status.icon}`}></i>
                                    <span>{status.label}</span>
                                </button>
                            ))}
                        </div>

                        <div className="star-rating">
                            {[...Array(10)].map((_, index) => {
                                index += 1;
                                return (
                                    <button
                                        key={index}
                                        className={`star ${index <= (hoverScore || userScore) ? 'on' : 'off'}`}
                                        onClick={() => setUserScore(userScore === index ? 0 : index)}
                                        onMouseEnter={() => setHoverScore(index)}
                                        onMouseLeave={() => setHoverScore(0)}
                                    >
                                        <i className="fas fa-star"></i>
                                        <span className="star-label">{index}</span>
                                    </button>
                                );
                            })}
                        </div>

                        <button className="submit-button" onClick={handleSubmit}>
                            <i className="fas fa-save"></i> Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
const AnimeDetailModal = ({ anime, isOpen, toggle }) => {
    const [isTrailerPopupOpen, setTrailerPopupOpen] = useState(false);
    const [isSynopsisExpanded, setSynopsisExpanded] = useState(false);

    const {
        title,
        images,
        synopsis,
        score,
        genres,
        episodes,
        status,
        duration,
        rating,
        trailer,
        aired,
        source,
    } = anime;

    const formatList = (items) => {
        return items && items.length > 0
            ? items.map((item) => item.name).join(', ')
            : 'N/A';
    };

    const toggleTrailerPopup = () => {
        setTrailerPopupOpen(!isTrailerPopupOpen);
    };

    const toggleSynopsis = () => {
        setSynopsisExpanded(!isSynopsisExpanded);
    };

    const synopsisPreview = synopsis
        ? synopsis.length > 250 && !isSynopsisExpanded
            ? synopsis.substring(0, 250) + '...'
            : synopsis
        : 'No synopsis available.';

    return (
        <>
            <CSSTransition in={isOpen} timeout={300} classNames="modal-animation" unmountOnExit>
                <Modal isOpen={isOpen} toggle={toggle} className="anime-detail-modal" size="xl" centered>
                    <ModalBody className="modal-body-custom">
                        <button className="modal-close-button" onClick={toggle}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                        <div className="anime-detail-content">
                            <div
                                className="anime-detail-background"
                                style={{
                                    backgroundImage: `url(${images.jpg.large_image_url})`,
                                }}
                            ></div>
                            <div className="anime-detail-content__overlay">
                                <div className="anime-detail-content__info">
                                    <h2 className="anime-detail-content__title">{title}</h2>
                                    <div className="anime-detail-content__meta">
                                        <span className="release-date">{aired?.string || 'N/A'}</span>
                                        <span className="rating">
                                            <i className="fas fa-star"></i> {score || 'N/A'}
                                        </span>
                                        <span className="genres">{formatList(genres)}</span>
                                    </div>
                                    <p
                                        className={`anime-detail-content__overview ${isSynopsisExpanded ? 'expanded' : ''}`}
                                    >
                                        {synopsisPreview}
                                    </p>
                                    {synopsis && synopsis.length > 250 && (
                                        <button className="read-more-button" onClick={toggleSynopsis}>
                                            {isSynopsisExpanded ? 'Read Less' : 'Read More'}
                                        </button>
                                    )}
                                    <div className="anime-detail-content__stats">
                                        <div className="stat">
                                            <span className="label">Episodes</span>
                                            <span className="value">{episodes || 'N/A'}</span>
                                        </div>
                                        <div className="stat">
                                            <span className="label">Duration</span>
                                            <span className="value">{duration || 'N/A'}</span>
                                        </div>
                                        <div className="stat">
                                            <span className="label">Status</span>
                                            <span className="value">{status || 'N/A'}</span>
                                        </div>
                                        <div className="stat">
                                            <span className="label">Source</span>
                                            <span className="value">{source || 'N/A'}</span>
                                        </div>
                                        <div className="stat">
                                            <span className="label">Rating</span>
                                            <span className="value">{rating || 'N/A'}</span>
                                        </div>
                                    </div>
                                    <div className="anime-detail-content__actions">
                                        {trailer?.embed_url && (
                                            <button className="details-button" onClick={toggleTrailerPopup}>
                                                <i className="fas fa-film"></i> Watch Trailers
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </CSSTransition>
            {isTrailerPopupOpen && (
                <Modal
                    isOpen={isTrailerPopupOpen}
                    toggle={toggleTrailerPopup}
                    className="trailer-popup-modal"
                    centered
                >
                    <ModalBody className="trailer-popup-body">
                        <div className="trailer-popup-video-wrapper">
                            <iframe
                                src={`${trailer.embed_url}?autoplay=1`}
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="Anime Trailer"
                                className="trailer-popup-video-iframe"
                            />
                        </div>
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};
const animeTypes = [
    { value: 'tv', label: 'TV' },
    { value: 'movie', label: 'Movie' },
    { value: 'ova', label: 'OVA' },
    { value: 'special', label: 'Special' },
    { value: 'ona', label: 'ONA' },
    { value: 'music', label: 'Music' },
];
const airingTypes = [
    { value: 'airing', label: 'Airing' },
    { value: 'complete', label: 'Complete' },
    { value: 'upcoming', label: 'Upcoming' },
];
const statusIcons = {
    Completed: faCheckCircle,
    Watching: faPlayCircle,
    'On-Hold': faPauseCircle,
    Dropped: faTimesCircle,
    'Plan to Watch': faClock,
};
export default function Anime() {
    const [animeSearchTextboxValue, setAnimeSearchTextboxValue] = useState('');
    const [animeTypeSelectboxValue, setAnimeTypeSelectboxValue] = useState('');
    const [airingTypeSelectboxValue, setAiringTypeSelectboxValue] = useState('');
    const [rangeValues, setRangeValues] = useState([0, 10]);
    const [yearsAndSeasons, setYearsAndSeasons] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [isFilterPanelVisible, setIsFilterPanelVisible] = useState(true);
    const [pageCountInfo, setPageCountInfo] = useState(1);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchedAnimeDataSource, setSearchedAnimeDataSource] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAnime, setSelectedAnime] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [detailAnime, setDetailAnime] = useState(null);
    const postRequestSyncPromise = usePostRequestSyncPromise();

    const openRatingModal = (anime) => {
        setSelectedAnime(anime);
        setModalOpen(true);
    };
    const closeRatingModal = () => {
        setModalOpen(false);
        setSelectedAnime(null);
    };
    const handleRatingSubmit = (updatedAnime) => {
        const userId = localStorage.getItem('userId');
        const postData = {
            series_animedb_id: updatedAnime.mal_id,
            series_title: updatedAnime.title,
            series_type: updatedAnime.type,
            series_episodes: updatedAnime.episodes,
            my_score: updatedAnime.my_score.toString(),
            my_status: updatedAnime.my_status,
            my_old_status: updatedAnime.my_status,
            userId: userId,
        };
        postRequestSyncPromise('UserAnimeProfile/InsertUserAnime', postData)
            .then(() => {
                setSearchedAnimeDataSource((prevData) =>
                    prevData.map((item) =>
                        item.mal_id === updatedAnime.mal_id ? updatedAnime : item
                    )
                );
                closeRatingModal();
            })
            .catch((error) => {
                console.error('Error updating anime:', error);
            });
    };
    const fetchAnimeSearch = useCallback(() => {
        setIsLoading(true);
        const searchFilter = {
            q: animeSearchTextboxValue,
            type: animeTypeSelectboxValue,
            status: airingTypeSelectboxValue,
            min_score: rangeValues[0],
            max_score: rangeValues[1],
            page: currentPage,
            userId: localStorage.getItem('userId'),
        };
        postRequestSyncPromise('Anime/SearchAnime', searchFilter)
            .then((data) => {
                setSearchedAnimeDataSource(data.data);
                setPageCountInfo(data.pagination.last_visible_page);
                setIsLoading(false);
                window.scrollTo(0, 0);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    }, [
        animeSearchTextboxValue,
        animeTypeSelectboxValue,
        airingTypeSelectboxValue,
        rangeValues,
        postRequestSyncPromise,
        currentPage,
    ]);
    const fetchSeasonAnimes = useCallback(() => {
        setIsLoading(true);
        const pageInformation = {
            year: selectedYear,
            season: selectedSeason,
            userId: localStorage.getItem('userId'),
            page: currentPage,
        };
        postRequestSyncPromise('Anime/SearchSeasons', pageInformation)
            .then((data) => {
                setSearchedAnimeDataSource(data.data);
                setPageCountInfo(data.pagination.last_visible_page);
                setIsLoading(false);
                window.scrollTo(0, 0);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    }, [
        postRequestSyncPromise,
        selectedYear,
        selectedSeason,
        currentPage,
    ]);
    const fetchSeasonNowAnimes = useCallback(() => {
        setIsLoading(true);
        const pageInformation = {
            page: currentPage,
            userId: localStorage.getItem('userId'),
        };
        postRequestSyncPromise('Anime/SeasonNowAnimes', pageInformation)
            .then((data) => {
                setSearchedAnimeDataSource(data.data);
                setPageCountInfo(data.pagination.last_visible_page);
                setIsLoading(false);
                window.scrollTo(0, 0);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    }, [postRequestSyncPromise, currentPage]);
    const fetchSeasons = useCallback(() => {
        postRequestSyncPromise('Anime/AllSeasons', {})
            .then((data) => {
                setYearsAndSeasons(data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [postRequestSyncPromise]);
    const handleSearchBySeason = () => {
        setIsSearchActive(false);
        setCurrentPage(1);
        fetchSeasonAnimes();
    };
    const handlePageClick = (data) => {
        let selectedPage = data.selected + 1;
        setCurrentPage(selectedPage);
    };
    const toggleFilterPanel = () => {
        setIsFilterPanelVisible(!isFilterPanelVisible);
        setIsSearchActive(false);
        setCurrentPage(1);
        setSearchedAnimeDataSource([]);
        setAnimeSearchTextboxValue('');
        setAnimeTypeSelectboxValue('');
        setAiringTypeSelectboxValue('');
        setRangeValues([0, 10]);
        setSelectedYear(null);
        setSelectedSeason(null);
    };
    useEffect(() => {
        if (currentPage > 0) {
            setIsLoading(true);
            if (isFilterPanelVisible) {
                if (isSearchActive) {
                    fetchAnimeSearch();
                } else {
                    fetchSeasonNowAnimes();
                }
            } else if (selectedYear && selectedSeason) {
                fetchSeasonAnimes();
            }
        }
    }, [currentPage]);
    useEffect(() => {
        if (!isFilterPanelVisible && yearsAndSeasons.length === 0) {
            fetchSeasons();
        }
    }, [isFilterPanelVisible, yearsAndSeasons.length, fetchSeasons]);
    const openDetailModal = (anime) => {
        setDetailAnime(anime);
        setDetailModalOpen(true);
    };
    const closeDetailModal = () => {
        setDetailModalOpen(false);
        setDetailAnime(null);
    };
    const getScoreClass = (score) => {
        const numScore = parseInt(score);
        if (numScore >= 8) return 'score-excellent';
        if (numScore >= 6) return 'score-good';
        if (numScore >= 4) return 'score-average';
        return 'score-poor';
    };
    const getStatusClass = (status) => {
        switch (status) {
            case 'Completed':
                return 'status-completed';
            case 'Watching':
                return 'status-watching';
            case 'On-Hold':
                return 'status-onhold';
            case 'Dropped':
                return 'status-dropped';
            case 'Plan to Watch':
                return 'status-plantowatch';
            default:
                return 'status-default';
        }
    };
    const renderAnimeCards = () => {
        return (
            <div className="anime-list">
                {searchedAnimeDataSource.map((anime) => (
                    <div key={anime.mal_id} className="anime-card">
                        <div
                            className="anime-card-inner"
                            onClick={() => openDetailModal(anime)}
                        >
                            <div className="anime-image-container">
                                <img
                                    src={anime.images.jpg.large_image_url}
                                    alt={anime.title}
                                    className="anime-image"
                                />
                                <div className="anime-score-overlay">
                                    <FontAwesomeIcon icon={faStar} /> {anime.score}
                                </div>
                                <button
                                    className="update-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openRatingModal(anime);
                                    }}
                                >
                                    <i className="fa fa-pencil-alt"></i>
                                </button>
                            </div>
                            {(anime.my_status || (anime.my_score && anime.my_score > 0)) && (
                                <div
                                    className={`anime-user-score ${anime.my_score > 0
                                        ? getScoreClass(anime.my_score)
                                        : getStatusClass(anime.my_status)
                                        } ${anime.my_score > 0 ? 'has-score' : ''}`}
                                >
                                    {anime.my_status ? (
                                        <FontAwesomeIcon icon={statusIcons[anime.my_status]} />
                                    ) : anime.my_score > 0 ? (
                                        <FontAwesomeIcon icon={faStar} />
                                    ) : null}
                                    {anime.my_score > 0 && <span>{anime.my_score}</span>}
                                </div>
                            )}
                            <div className="anime-card-content">
                                <h5 className="anime-title">{anime.title}</h5>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };
    const filterPanel = () => {
        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                backgroundColor: 'transparent',
                borderColor: state.isFocused
                    ? 'var(--glow-color)'
                    : 'var(--border-color)',
                boxShadow: state.isFocused ? `0 0 8px var(--glow-color)` : 'none',
                '&:hover': {
                    borderColor: 'var(--glow-color)',
                },
            }),
            singleValue: (provided) => ({
                ...provided,
                color: 'var(--text-color)',
            }),
            placeholder: (provided) => ({
                ...provided,
                color: 'rgba(255, 255, 255, 0.6)',
                fontStyle: 'italic',
            }),
            indicatorSeparator: () => ({
                display: 'none',
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                color: state.isFocused ? 'var(--glow-color)' : 'var(--text-color)',
                '&:hover': {
                    color: 'var(--glow-color)',
                },
            }),
            menu: (provided) => ({
                ...provided,
                backgroundColor: 'rgba(32, 43, 96, 0.9)',
                border: '1px solid var(--border-color)',
                boxShadow: `0 8px 16px var(--glow-color)`,
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                    ? 'var(--hover-gradient-2)'
                    : state.isFocused
                        ? 'var(--hover-gradient-1)'
                        : 'transparent',
                color: 'var(--text-color)',
                '&:active': {
                    backgroundColor: 'var(--primary-gradient-2)',
                },
            }),
        };
        return (
            <div className="search-bar">
                <Row>
                    <Col xs="3">
                        <div className="input-with-icon">
                            <input
                                type="text"
                                className="search-input"
                                value={animeSearchTextboxValue}
                                onChange={(e) => setAnimeSearchTextboxValue(e.target.value)}
                                placeholder="Search Anime..."
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setIsSearchActive(true);
                                        setCurrentPage(1);
                                        fetchAnimeSearch();
                                    }
                                }}
                            />
                            <FaSearch className="input-icon" />
                        </div>
                    </Col>
                    <Col xs="3">
                        <Select
                            styles={customStyles}
                            options={animeTypes}
                            value={animeTypes.find(
                                (option) => option.value === animeTypeSelectboxValue
                            )}
                            onChange={(selectedOption) =>
                                setAnimeTypeSelectboxValue(
                                    selectedOption ? selectedOption.value : ''
                                )
                            }
                            placeholder="Type"
                            isClearable
                            classNamePrefix="filter-select"
                            components={{
                                DropdownIndicator: () => <FaFilm className="select-icon" />,
                            }}
                        />
                    </Col>
                    <Col xs="3">
                        <Select
                            styles={customStyles}
                            options={airingTypes}
                            value={airingTypes.find(
                                (option) => option.value === airingTypeSelectboxValue
                            )}
                            onChange={(selectedOption) =>
                                setAiringTypeSelectboxValue(
                                    selectedOption ? selectedOption.value : ''
                                )
                            }
                            placeholder="Status"
                            isClearable
                            classNamePrefix="filter-select"
                            components={{
                                DropdownIndicator: () => (
                                    <FaBroadcastTower className="select-icon" />
                                ),
                            }}
                        />
                    </Col>
                    <Col xs="3">
                        <div className="slider-container">
                            <ReactSlider
                                className="dual-slider"
                                thumbClassName="dual-slider-thumb"
                                trackClassName="dual-slider-track"
                                min={0}
                                max={10}
                                step={1}
                                value={rangeValues}
                                onChange={(values) => setRangeValues(values)}
                                renderThumb={(props, state) => (
                                    <div {...props}>
                                        <div className="thumb-value">{state.valueNow}</div>
                                    </div>
                                )}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="filter-buttons">
                    <Col xs="6">
                        <button
                            className="filter-button"
                            onClick={() => {
                                setIsSearchActive(true);
                                setCurrentPage(1);
                                fetchAnimeSearch();
                            }}
                        >
                            <FaSearch />
                        </button>
                    </Col>
                    <Col xs="6">
                        <button className="filter-button" onClick={toggleFilterPanel}>
                            <FaSlidersH />
                        </button>
                    </Col>
                </Row>
            </div>
        );
    };
    const renderSeasonFilterPanel = () => {
        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                backgroundColor: 'transparent',
                borderColor: state.isFocused
                    ? 'var(--glow-color)'
                    : 'var(--border-color)',
                boxShadow: state.isFocused ? `0 0 8px var(--glow-color)` : 'none',
                '&:hover': {
                    borderColor: 'var(--glow-color)',
                },
            }),
            singleValue: (provided) => ({
                ...provided,
                color: 'var(--text-color)',
            }),
            placeholder: (provided) => ({
                ...provided,
                color: 'rgba(255, 255, 255, 0.6)',
                fontStyle: 'italic',
            }),
            indicatorSeparator: () => ({
                display: 'none',
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                color: state.isFocused ? 'var(--glow-color)' : 'var(--text-color)',
                '&:hover': {
                    color: 'var(--glow-color)',
                },
            }),
            menu: (provided) => ({
                ...provided,
                backgroundColor: 'rgba(32, 43, 96, 0.9)',
                border: '1px solid var(--border-color)',
                boxShadow: `0 8px 16px var(--glow-color)`,
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                    ? 'var(--hover-gradient-2)'
                    : state.isFocused
                        ? 'var(--hover-gradient-1)'
                        : 'transparent',
                color: 'var(--text-color)',
                '&:active': {
                    backgroundColor: 'var(--primary-gradient-2)',
                },
            }),
        };
        return (
            <div className="search-bar">
                <Row>
                    <Col xs="6">
                        <Select
                            styles={customStyles}
                            options={yearsAndSeasons.map((item) => ({
                                value: item.year,
                                label: item.year,
                            }))}
                            value={
                                selectedYear ? { value: selectedYear, label: selectedYear } : null
                            }
                            onChange={(selectedOption) => {
                                setSelectedYear(selectedOption ? selectedOption.value : null);
                                setSelectedSeason(null);
                            }}
                            placeholder="Year"
                            isClearable
                            classNamePrefix="filter-select"
                            components={{
                                DropdownIndicator: () => <FaSyncAlt className="select-icon" />,
                            }}
                        />
                    </Col>
                    <Col xs="6">
                        <Select
                            styles={customStyles}
                            options={
                                selectedYear
                                    ? yearsAndSeasons
                                        .find((item) => item.year === selectedYear)
                                        .seasons.map((season) => ({
                                            value: season,
                                            label: season,
                                        }))
                                    : []
                            }
                            value={
                                selectedSeason
                                    ? { value: selectedSeason, label: selectedSeason }
                                    : null
                            }
                            onChange={(selectedOption) =>
                                setSelectedSeason(selectedOption ? selectedOption.value : null)
                            }
                            placeholder="Season"
                            isClearable
                            isDisabled={!selectedYear}
                            classNamePrefix="filter-select"
                            components={{
                                DropdownIndicator: () => <FaLeaf className="select-icon" />,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="filter-buttons">
                    <Col xs="6">
                        <button
                            className="filter-button"
                            onClick={handleSearchBySeason}
                            disabled={!selectedYear || !selectedSeason}
                        >
                            <FaSearch />
                        </button>
                    </Col>
                    <Col xs="6">
                        <button className="filter-button" onClick={toggleFilterPanel}>
                            <FaSlidersH />
                        </button>
                    </Col>
                </Row>
            </div>
        );
    };
    return (
        <div className="anime-container">
            {isFilterPanelVisible ? filterPanel() : renderSeasonFilterPanel()}
            <br />
            {isLoading ? (
                <div className="loading">Loading...</div>
            ) : (
                renderAnimeCards()
            )}
            <div className="pagination-container">
                <ReactPaginate
                    previousLabel={'back'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    pageCount={pageCountInfo}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    forcePage={currentPage - 1}
                />
            </div>
            {modalOpen && selectedAnime && (
                <AnimeRating
                    anime={selectedAnime}
                    onClose={closeRatingModal}
                    onSubmit={handleRatingSubmit}
                />
            )}
            {detailModalOpen && detailAnime && (
                <AnimeDetailModal
                    anime={detailAnime}
                    isOpen={detailModalOpen}
                    toggle={closeDetailModal}
                />
            )}
        </div>
    );
}