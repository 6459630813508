import React, { useState, useContext } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
  FaHome,
  FaDiscord,
  FaFilm,
  FaTv,
  FaStickyNote,
  FaUsers,
  FaSignOutAlt,
  FaUserCircle,
  FaCog,
} from 'react-icons/fa';
import { GiLightningStorm, GiKimono } from 'react-icons/gi';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './CustomNavbar.css';
import UserSettings from '../ProfileActions/Settings';

function CustomNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggle = () => setIsOpen(!isOpen);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const toggleSettingsModal = () => {
    setIsSettingsOpen(!isSettingsOpen);
    setDropdownOpen(false);
  };

  if (!isAuthenticated) {
    return null;
  }

  const userData = JSON.parse(localStorage.getItem('userData'));
  const { userName, avatarUrl } = userData;

  return (
    <>
      <Navbar expand="md" className="kaminari-navbar">
        <div className="navbar-left">
          <NavbarBrand href="/" className="kaminari-navbar-brand">
            <div className="logo-container">
              <GiLightningStorm className="logo-icon" />
              <span className="brand-text">Kaminari</span>
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} className="kaminari-navbar-toggler" />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="me-auto kaminari-nav" navbar>
            <NavItem>
                <NavLink
                  href="/anime"
                  className={`kaminari-nav-link ${
                    location.pathname === '/anime' ? 'active' : ''
                  }`}
                >
                  <GiKimono className="nav-icon" />
                  AnimeList
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/kaminaricord"
                  className={`kaminari-nav-link ${
                    location.pathname === '/kaminaricord' ? 'active' : ''
                  }`}
                >
                  <FaDiscord className="nav-icon" />
                  KaminariCord
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/movie"
                  className={`kaminari-nav-link ${location.pathname === '/movie' ? 'active' : ''}`}
                >
                  <FaFilm className="nav-icon" />
                  Movie
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/tvshow"
                  className={`kaminari-nav-link ${location.pathname === '/tvshow' ? 'active' : ''}`}
                >
                  <FaTv className="nav-icon" />
                  TV Show
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/notes"
                  className={`kaminari-nav-link ${location.pathname === '/notes' ? 'active' : ''}`}
                >
                  <FaStickyNote className="nav-icon" />
                  Notes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/together"
                  className={`kaminari-nav-link ${
                    location.pathname === '/together' ? 'active' : ''
                  }`}
                >
                  <FaUsers className="nav-icon" />
                  Watch Together
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
        <div className="navbar-right">
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="user-dropdown">
            <DropdownToggle caret className="user-dropdown-toggle">
              <img
                src={`data:image/png;base64,${avatarUrl}`}
                alt="User Avatar"
                className="user-avatar"
              />
              <span className="user-name">{userName}</span>
            </DropdownToggle>
            <DropdownMenu right className={`dropdown-menu-custom ${!dropdownOpen ? 'hidden' : ''}`}>
              <DropdownItem onClick={() => navigate('/profile')}>
                <FaUserCircle className="dropdown-icon" /> Profile
              </DropdownItem>
              <DropdownItem onClick={toggleSettingsModal}>
                <FaCog className="dropdown-icon" /> Profile Settings
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={handleLogout}>
                <FaSignOutAlt className="dropdown-icon" /> Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </Navbar>
      {isSettingsOpen && (
        <UserSettings isOpen={isSettingsOpen} toggle={toggleSettingsModal} />
      )}
    </>
  );
}

export default CustomNavbar;
